import { Avatar, Badge, List, ListItem } from "@chakra-ui/react";
import avatarReal from "../../assets/logotipos/Isotipo-Color.jpg";
import React from "react";

const AdminDetail = ({ user }) => {
  return (
    <List spacing={3}>
      <ListItem>
        <Avatar size={"xs"} src={avatarReal} mr={"2"} />
        Especialidades:
        {user.especialidades.map((esp, index) => (
          <Badge colorScheme="purple" mx={1} key={index}>
            {esp}
          </Badge>
        ))}
      </ListItem>
      <ListItem>
        <Avatar size={"xs"} src={avatarReal} mr={"2"} />
        CM: {user.identificacionMedica}
      </ListItem>
    </List>
  );
};

export default AdminDetail;
