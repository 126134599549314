import React from "react";
import {
  FormControl,
  Box,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderTrack,
  RangeSliderThumb,
  Text,
  Select,
} from "@chakra-ui/react";
const FormHorariosDisp = ({
  selectedHours,
  selectedDays,
  register,
  handleHourChange,
  handleDayChange,
  dayLabels,
}) => {
  return (
    <Box py={2}>
      <FormControl isRequired>
        <Text as={"b"} color={"eira.2.7"}>
          Horario de trabajo
        </Text>

        <Box mt={2}>
          <Text as={"b"} color={"eira.2"} fontSize={"xs"}>
            Rango de horas
          </Text>
          <RangeSlider
            defaultValue={selectedHours}
            min={0}
            max={24}
            step={1}
            onChange={handleHourChange}
          >
            <RangeSliderTrack>
              <RangeSliderFilledTrack />
            </RangeSliderTrack>
            <RangeSliderThumb index={0} />
            <RangeSliderThumb index={1} />
          </RangeSlider>
          <Box>
            {selectedHours[0]}:00 - {selectedHours[1]}:00
          </Box>
        </Box>
        <Box mt={2}>
          <Text as={"b"} color={"eira.2"} fontSize={"xs"}>
            Dias de la semana
          </Text>
          <RangeSlider
            defaultValue={selectedDays}
            min={1}
            max={7}
            step={1}
            onChange={handleDayChange}
          >
            <RangeSliderTrack>
              <RangeSliderFilledTrack />
            </RangeSliderTrack>
            <RangeSliderThumb index={0} />
            <RangeSliderThumb index={1} />
          </RangeSlider>
          <Box>
            {dayLabels[selectedDays[0] - 1]} a {dayLabels[selectedDays[1] - 1]}
          </Box>
        </Box>
      </FormControl>
      <Text as={"b"} color={"eira.2"} fontSize={"xs"}>
        Duracion de la consulta
      </Text>
      <FormControl isRequired py={2}>
        <Select {...register("duracionConsulta")}>
          <option value={10}>10 minutos</option>
          <option value={20}>20 minutos</option>
          <option value={30}>30 minutos</option>
          <option value={40}>40 minutos</option>
          <option value={50}>50 minutos</option>
          <option value={60}>60 minutos</option>
        </Select>
      </FormControl>
    </Box>
  );
};

export default FormHorariosDisp;
