import React from "react";
import { Link } from "react-router-dom";
import { IconButton, Text } from "@chakra-ui/react";

import { FiUsers } from "react-icons/fi";
import { BiBriefcaseAlt } from "react-icons/bi";
import { FaChartLine } from "react-icons/fa";

const SidebarSeller = ({ path }) => {
  return (
    <>
      <Link to={"/client"}>
        <IconButton
          background={path === "client" ? "eira.2.1" : "none"}
          mt={5}
          fontSize="2xl"
          color="eira.2"
          _hover={{ textDecor: "none", backgroundColor: "#ffff" }}
          icon={<FiUsers />}
        />
        <Text fontSize={12} textAlign="center" color="eira.2">
          Clients
        </Text>
      </Link>

      <Link to={"/service"}>
        <IconButton
          background={path === "service" ? "eira.2.1" : "none"}
          mt={5}
          fontSize="2xl"
          color="eira.2"
          _hover={{ textDecor: "none", backgroundColor: "#ffff" }}
          icon={<BiBriefcaseAlt />}
        />
        <Text fontSize={12} textAlign="center" color="eira.2">
          Services
        </Text>
      </Link>
      <Link to={"/proposar"}>
        <IconButton
          background={path === "proposar" ? "eira.2.1" : "none"}
          mt={5}
          fontSize="2xl"
          color="eira.2"
          _hover={{ textDecor: "none", backgroundColor: "#ffff" }}
          icon={<FaChartLine />}
        />
        <Text fontSize={12} textAlign="center" color="eira.2">
          Proposars
        </Text>
      </Link>
    </>
  );
};

export default SidebarSeller;
