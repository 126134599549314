import React from "react";
import Statistics from "./AdminStatitistic";
import { Box } from "@chakra-ui/react";

const Dashboard = () => {
  return (
    <Box mt={20} px={10}>
      <Statistics />
    </Box>
  );
};

export default Dashboard;
