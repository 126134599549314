import { configureStore } from "@reduxjs/toolkit";
/* import logger from "redux-logger"; */
import userReducer from "./reducers/user.reducer";
import adminReducer from "./reducers/admin.reducer";
import specialityReducer from "./reducers/speciality.reducer";
import doctorReducer from "./reducers/doctor.reducer";
import patientReducer from "./reducers/patient.reducer";
import medicineReducer from "./reducers/medicine.reducer";
import patientsByDoctor from "./reducers/patientsByDoctor.reducer";
import citas from "./reducers/citas.reducer";
import consultas from "./reducers/consulta.reducer";

const store = configureStore({
/*   middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger), */
  reducer: {
    user: userReducer,
    admins: adminReducer,
    specialities: specialityReducer,
    doctors: doctorReducer,
    patients: patientReducer,
    medicines: medicineReducer,
    patientsByDoctor: patientsByDoctor,
    citas: citas,
    consultas: consultas,
  },
});

export default store;
