import React from "react";
import { FiUserCheck } from "react-icons/fi";
import { RiUserSettingsLine } from "react-icons/ri";
import { AiOutlineShopping } from "react-icons/ai";
import { FiUsers } from "react-icons/fi";
import { MdOutlineDesignServices } from "react-icons/md";
import IconButtonNavbar from "../../commons/IconButtonNavbar";

const SidebarAdmin = ({ user, path }) => {
  return (
    <>
      <IconButtonNavbar
        link={"/admin"}
        text={"Admin"}
        icon={<RiUserSettingsLine fontSize={"25px"} />}
        path={path}
      />

      <IconButtonNavbar
        link={"/medicos"}
        text={"Medicos"}
        icon={<FiUsers fontSize={"25px"} />}
        path={path}
      />
      <IconButtonNavbar
        link={"/pacientes"}
        text={"Pacientes"}
        icon={<FiUserCheck fontSize={"25px"} />}
        path={path}
      />
      <IconButtonNavbar
        link={"/vendedores"}
        text={"Vendedores"}
        icon={<AiOutlineShopping fontSize={"25px"} />}
        path={path}
      />
      <IconButtonNavbar
        link={"/servicios"}
        text={"Servicios"}
        icon={<MdOutlineDesignServices fontSize={"25px"} />}
        path={path}
      />
    </>
  );
};

export default SidebarAdmin;
