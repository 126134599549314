import React from "react";
import { Flex } from "@chakra-ui/react";
import { FiSettings } from "react-icons/fi";
import { AiOutlineHome } from "react-icons/ai";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import SidebarAdmin from "./admin/SidebarAdmin";
import SidebarSeller from "./seller/SidebarSeller";
import SidebarDoctor from "./doctor/SidebarDoctor";
import SidebarPatient from "./patient/SidebarPatient";
import IconButtonNavbar from "../commons/IconButtonNavbar";

const Sidebar = () => {
  const path = useLocation().pathname.slice(1);
  const user = useSelector((state) => state.user);

  return (
    <>
      <Flex
        bg={"white"}
        pos="sticky"
        left="5"
        h="620px"
        pb={2}
        marginTop="2.5vh"
        boxShadow="0 4px 12px 0 rgba(0,0,0,0.5)"
        borderTopRightRadius={"15px"}
        borderBottomRightRadius={"15px"}
        w={"75px"}
        flexDir="column"
        justifyContent="center"
        alignItems="center"
      >
        <Flex p="5%" flexDir="column" alignItems={"center"} as="nav">
          <IconButtonNavbar
            link={"/"}
            text={"Inicio"}
            icon={<AiOutlineHome fontSize={"25px"} />}
            path={path}
          />
          {user.role === "admin" && <SidebarAdmin user={user} path={path} />}
          {user.role === "seller" && <SidebarSeller path={path} />}
          {user.role === "doctor" && <SidebarDoctor path={path} />}
          {user.role === "patient" && <SidebarPatient path={path} />}
          <IconButtonNavbar
            link={"/config"}
            text={"Config"}
            icon={<FiSettings fontSize={"25px"} />}
            path={path}
          />
        </Flex>
      </Flex>
    </>
  );
};

export default Sidebar;
