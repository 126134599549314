import axios from "axios";
import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import { endpointUser } from "../../utils/endpoints/apiUser";

const role = "doctor";
const initialState = [];

export const setDoctor = createAction("SET_DOCTOR");

export const createDoctor = createAsyncThunk(
  "CREATE_DOCTOR",
  async (formData) => {
    const response = await axios.post(endpointUser.create(role), formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  }
);
export const getAllDoctors = createAsyncThunk("GET_DOCTORS", () => {
  return axios.get(endpointUser.getAll(role)).then((r) => r.data);
});
export const getDoctorById = createAsyncThunk("GET_DOCTOR", (doctorId) => {
  return axios.get(endpointUser.getById(role, doctorId)).then((r) => r.data);
});
export const updateDoctor = createAsyncThunk("UPDATE_DOCTOR", async (data) => {
  const response = await axios.put(endpointUser.update(role), data);
  return response.data;
});
export const deleteDoctor = createAsyncThunk(
  "DELETE_DOCTOR",
  async (userId) => {
    const response = await axios.delete(endpointUser.delete(role, userId));
    return response.data;
  }
);

const doctorReducer = createReducer(initialState, {
  [getAllDoctors.fulfilled]: (state, action) => action.payload,
  [deleteDoctor.fulfilled]: (state, action) => {
    const { acknowledged, deletedCount } = action.payload;
    if (acknowledged && deletedCount === 1) {
      const filteredUsers = state.filter(
        (user) => user._id !== action.meta.arg
      );
      return filteredUsers;
    } else {
      return state;
    }
  },
  [createDoctor.fulfilled]: (state, action) => {
    state.push(action.payload);
  },
  [setDoctor]: (state, action) => action.payload,
  [getDoctorById.fulfilled]: (state, action) => action.payload,
  [updateDoctor.fulfilled]: (state, action) => {
    const updatedDoctor = action.payload;
    const index = state.findIndex((doctor) => doctor._id === updatedDoctor._id);
    if (index !== -1) {
      state[index] = updatedDoctor;
    }
  },
});

export default doctorReducer;
