import { FormControl, Select, Switch, Text } from "@chakra-ui/react";
import React from "react";
import EditUserForm from "../user/EditUserForm";

const EditAdmin = ({
  register,
  superAdmin,
  position,
  name,
  onChange,
  _id,
  address,
  phone,
  identificacion,
}) => {
  return (
    <>
      <EditUserForm
        identificacion={identificacion}
        register={register}
        name={name}
        onChange={onChange}
        _id={_id}
        address={address}
        phone={phone}
      />
      <FormControl isRequired>
        <Text as={"b"} color={"eira.2.7"}>
          Posición
        </Text>
        <Select
          defaultValue={position}
          {...register("position")}
          focusBorderColor="eira.2"
          color={"eira.2"}
        >
          <option value="ceo">CEO</option>
          <option value="admin">Administrador</option>
          <option value="developer">Programador</option>
        </Select>
      </FormControl>
      <FormControl isRequired>
        <FormControl
          display="flex"
          alignItems="center"
          pt={3}
          textAlign={"center"}
        >
          <Text as={"b"} color={"eira.2.7"} textAlign={"center"} mr={6}>
            Super Admin?
          </Text>
          <Switch defaultChecked={superAdmin} {...register("superAdmin")} />
        </FormControl>
      </FormControl>
    </>
  );
};

export default EditAdmin;
