import axios from "axios";
import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import { endpointUser } from "../../utils/endpoints/apiUser";

const role = "patient";
const initialState = [];

export const setPatient = createAction("SET_PATIENT");
export const createPatient = createAsyncThunk(
  "CREATE_PATIENT",
  async (formData) => {
    const response = await axios.post(endpointUser.create(role), formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  }
);
export const getAllPatients = createAsyncThunk("GET_PATIENTS", () => {
  return axios.get(endpointUser.getAll(role)).then((r) => r.data);
});
export const getPatientById = createAsyncThunk("GET_PATIENT", (patientId) => {
  return axios.get(endpointUser.getById(role, patientId)).then((r) => r.data);
});
export const updatePatient = createAsyncThunk(
  "UPDATE_PATIENT",
  async (data) => {
    const response = await axios.put(endpointUser.update(role), data);
    return response.data;
  }
);
export const deletePatient = createAsyncThunk(
  "DELETE_PATIENT",
  async (userId) => {
    const response = await axios.delete(endpointUser.delete(role, userId));
    return response.data;
  }
);
const patientReducer = createReducer(initialState, {
  [getAllPatients.fulfilled]: (state, action) => action.payload,
  [deletePatient.fulfilled]: (state, action) => {
    const { acknowledged, deletedCount } = action.payload;
    if (acknowledged && deletedCount === 1) {
      const filteredUsers = state.filter(
        (user) => user._id !== action.meta.arg
      );
      return filteredUsers;
    } else {
      return state;
    }
  },
  [createPatient.fulfilled]: (state, action) => {
    state.push(action.payload);
  },
  [setPatient]: (state, action) => action.payload,
  [getPatientById.fulfilled]: (state, action) => action.payload,
  [updatePatient.fulfilled]: (state, action) => {
    const updatedPatient = action.payload;
    const index = state.findIndex(
      (patient) => patient._id === updatedPatient._id
    );
    if (index !== -1) {
      state[index] = updatedPatient;
    }
  },
});

export default patientReducer;
