import { Button } from "@chakra-ui/react";
import Swal from "sweetalert2";
import React from "react";
import { useDispatch } from "react-redux";
import { AiOutlineDelete } from "react-icons/ai";
import { deleteAdmin } from "../store/reducers/admin.reducer";
import { deleteDoctor } from "../store/reducers/doctor.reducer";
import { deletePatient } from "../store/reducers/patient.reducer";
import { deleteSpeciality } from "../store/reducers/speciality.reducer";
import { deleteMedicine } from "../store/reducers/medicine.reducer";

const DeleteUser = ({ user, typeOfItem }) => {
  const dispatch = useDispatch();
  const role = {
    admin: deleteAdmin,
    doctor: deleteDoctor,
    pacientes: deletePatient,
    especialidades: deleteSpeciality,
    medicinas: deleteMedicine,
  };

  //SUBMIT
  const deleteUser = async () => {
    const { value: confirmed } = await Swal.fire({
      title: `¿Estás seguro de eliminar a ${
        user.name || user.brandName.split(" ").slice(0, 2).join(" ")
      }?`,
      confirmButtonText: "Eliminar",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      allowOutsideClick: false,
      cancelButtonColor: "red",
      focusCancel: true,
      input: "checkbox",
      inputPlaceholder: "Eliminar permanentemente",
      inputAttributes: {
        "aria-label": "Eliminar permanentemente",
      },
    });

    if (confirmed) {
      const resp = await dispatch(role[typeOfItem](user._id));

      if (resp) {
        await Swal.fire({
          title: "Eliminado",
          text: "El usuario ha sido eliminado correctamente",
          icon: "success",
        });
      }
    } else {
      await Swal.fire({
        title: "Cancelado",
        text: "La eliminación del usuario ha sido cancelada",
        icon: "info",
      });
    }
  };

  return (
    <>
      <Button
        bg={"red"}
        size={"sm"}
        onClick={deleteUser}
        display="flex"
        justifyContent="center"
        alignItems="center"
        _hover={{
          bg: "eira.1.5",
        }}
      >
        <AiOutlineDelete fontSize={20} color="white" />
      </Button>
    </>
  );
};

export default DeleteUser;
