import React, { useEffect, useState } from "react";
import {
  Box,
  Center,
  Text,
  Badge,
  Flex,
  Heading,
  Wrap,
  WrapItem,
  Avatar,
} from "@chakra-ui/react";
import QRCode from "qrcode.react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import API_BASE_URL from "../../../utils/apiBaseURL";
import { useMediaQuery } from "@chakra-ui/react";
import defaultAvatar from "../../../assets/userO.png";

const ConsultaDetail = () => {
  const [consulta, setConsulta] = useState(null);
  const { consultaId } = useParams();
  const [isDesktop] = useMediaQuery("(min-width: 800px)");

  useEffect(() => {
    const fetchConsulta = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/consultas/${consultaId}`
        );
        setConsulta(response.data);
      } catch (error) {
        console.error("Error al obtener la consulta:", error);
        setConsulta([]);
      }
    };

    fetchConsulta();
  }, [consultaId]);

  if (consulta === null) {
    return (
      <Text textAlign="center" color="eira.2" mt={20}>
        Cargando...
      </Text>
    );
  }

  if (consulta.length === 0) {
    return (
      <Text textAlign="center" color="eira.2" mt={20}>
        Consulta no existe
      </Text>
    );
  }

  const profilePhoto =
    consulta.paciente &&
    `${API_BASE_URL}/api/utils/uploads/profilePhoto/${consulta.paciente.profilePhoto}`;
  const fecha = consulta.paciente && new Date(consulta.paciente.dateOfBirth);
  const anio = fecha && fecha.getFullYear();
  const edad = fecha && 2023 - anio;

  return (
    <Box
      bg={"white"}
      py={3}
      borderRadius={"2xl"}
      mt={5}
      width={!isDesktop ? "95vw" : "100%"}
      px={isDesktop && 10}
      pb={10}
    >
      <Box>
        <Center>
          <Avatar size={"xl"} src={profilePhoto || defaultAvatar} />
        </Center>
        <Text mt={2} color={"eira.2"} fontSize={"xl"} textAlign={"center"}>
          {consulta.paciente && consulta.paciente.name}
        </Text>

        <Text color={"eira.2"} fontSize={"xl"} textAlign={"center"}>
          Edad: {edad}
        </Text>
        <Flex flexWrap="wrap" justifyContent="center" textAlign="center" mt={4}>
          <Text color={"eira.1"} mx={1}>
            Temp: {consulta.variables.temperatura}
          </Text>
          <Text color={"eira.1"} mx={1}>
            Pulso: {consulta.variables.pulso}
          </Text>
          <Text color={"eira.1"} mx={1}>
            Resp: {consulta.variables.respiracion}
          </Text>
          <Text color={"eira.1"} mx={1}>
            Tensión: {consulta.variables.tensionArterialMN}/
            {consulta.variables.tensionArterialMX}
          </Text>
        </Flex>
      </Box>

      <Heading textAlign={"center"} color={"eira.2"} size={"lg"} mt={10}>
        Consulta
      </Heading>
      <Center>
        {consulta.consulta.motivoConsulta.length > 0 && (
          <Box maxWidth={600} mx={2}>
            <Text color="eira.2" fontSize="xl" mb={2} textAlign="center">
              Motivo de consulta
            </Text>
            <Wrap justify="center">
              {consulta.consulta.motivoConsulta.map((mot, index) => (
                <WrapItem key={index}>
                  <Badge
                    variant="outline"
                    colorScheme="purple"
                    mx={1}
                    color="eira.1"
                  >
                    {mot}
                  </Badge>
                </WrapItem>
              ))}
            </Wrap>
          </Box>
        )}
      </Center>
      {consulta.consulta.enfermedadActual.length > 0 && (
        <Box mt={10} alignSelf="center">
          <Text color="eira.2" fontSize="xl" mb={2} textAlign="center">
            Enfermedad Actual
          </Text>
          <Box overflow="hidden">
            <Text textAlign="center">{consulta.consulta.enfermedadActual}</Text>
          </Box>
        </Box>
      )}
      {consulta.consulta.diagnosticoConsulta.length > 0 && (
        <Box mt={10} alignSelf="center">
          <Text color="eira.2" fontSize="xl" mb={2} textAlign="center">
            Diagnóstico
          </Text>
          <Box overflow="hidden">
            <Text textAlign="center">
              {consulta.consulta.diagnosticoConsulta}
            </Text>
          </Box>
        </Box>
      )}

      {consulta.examenes.length > 0 && (
        <Box mt={10}>
          <Text color={"eira.2"} fontSize={"xl"} mb={2} textAlign={"center"}>
            Examenes a realizar
          </Text>
          <Flex flexWrap="wrap" justifyContent="center" textAlign="center">
            {consulta.examenes.map((mot, index) => (
              <Badge
                key={index}
                variant="outline"
                colorScheme="purple"
                mx={2}
                my={1}
                color={"eira.1"}
              >
                {mot}
              </Badge>
            ))}
          </Flex>
        </Box>
      )}
      {consulta.medicamentos.length > 0 && (
        <Box mt={10}>
          <Text color={"eira.2"} fontSize={"xl"} mb={2} textAlign={"center"}>
            Medicamentos
          </Text>
          {consulta.medicamentos.map((med, index) => (
            <Flex
              key={index}
              flexDir={"column"}
              border={"1px"}
              borderColor={"eira.2"}
              borderRadius={"xl"}
              mt={2}
              p={2}
            >
              <Text textAlign="center" style={{ wordWrap: "break-word" }}>
                <span style={{ color: "rgba(0, 191, 115)" }}>
                  Medicamento:{" "}
                </span>
                {med.medicamento}
              </Text>

              <Text textAlign="center">
                <span style={{ color: "rgba(0,191,115)" }}>Dosis: </span>{" "}
                {med.dosis}
              </Text>
              <Text textAlign="center">
                <span style={{ color: "rgba(0,191,115)" }}>Frecuencia: </span>{" "}
                {med.frecuencia}
              </Text>
            </Flex>
          ))}
          <Box textAlign="center">
            <Text
              color={"eira.2"}
              fontSize={"xl"}
              mb={5}
              textAlign={"center"}
              mt={5}
            >
              Recipe
            </Text>
            <Link to={`https://app.eiraplus.com/recipe/${consulta._id}`}>
              <QRCode
                value={`https://app.eiraplus.com/recipe/${consulta._id}`}
                size={100}
                fgColor="rgba(0, 191, 115, 1)"
                style={{ margin: "0 auto" }}
              />
            </Link>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ConsultaDetail;
