import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Stack,
  Text,
  Center,
  Image,
} from "@chakra-ui/react";
import logo from "../assets/logotipos/Logo-Original.png";
import defaultAvatar from "../assets/userO.png";
import { useDispatch, useSelector } from "react-redux";
import { logoutRequest } from "../store/reducers/user.reducer";
import { setAdmin } from "../store/reducers/admin.reducer";
import API_BASE_URL from "../utils/apiBaseURL";
import { useMediaQuery } from "@chakra-ui/react";
import { GiHamburgerMenu } from "react-icons/gi";

export default function Navbar() {
  const [isDesktop] = useMediaQuery("(min-width: 800px)");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  const logout = async () => {
    const resp = await dispatch(logoutRequest());
    if (resp) {
      navigate("/");
      dispatch(setAdmin({ state: "rejected" }));
    }
  };

  let profilePhoto = `${API_BASE_URL}/api/utils/uploads/profilePhoto/${user.profilePhoto}`;

  return (
    <>
      {isDesktop ? (
        <Box bg="white" px={4} boxShadow="xl">
          <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
            <Link to={"/"}>
              <Box>
                <Image src={logo} height="200px" p={2} />
              </Box>
            </Link>
            <Flex alignItems={"center"}>
              <Stack direction={"row"} spacing={7}>
                {user.email ? (
                  <Menu>
                    <MenuButton
                      as={Button}
                      rounded={"full"}
                      variant={"link"}
                      cursor={"pointer"}
                      minW={0}
                    >
                      <Avatar size={"sm"} src={profilePhoto || defaultAvatar} />
                    </MenuButton>
                    <MenuList alignItems={"center"}>
                      <br />
                      <Center>
                        <Avatar
                          size={"xl"}
                          src={profilePhoto || defaultAvatar}
                        />
                      </Center>
                      <br />
                      <Center>
                        <Text as="b" color={"eira.1"}>
                          {user.name.split(" ").slice(0, 2).join(" ")}
                        </Text>
                      </Center>
                      <br />
                      <MenuDivider />
                      <Link to={"/settings"}>
                        <MenuItem color={"eira.1"} _hover={{ bg: "eira.2" }}>
                          Your Account
                        </MenuItem>
                      </Link>
                      <MenuItem
                        onClick={logout}
                        color={"eira.1"}
                        _hover={{ bg: "eira.2" }}
                      >
                        Logout
                      </MenuItem>
                    </MenuList>
                  </Menu>
                ) : (
                  <></>
                )}
              </Stack>
            </Flex>
          </Flex>
        </Box>
      ) : (
        <Box bg="white" px={4} boxShadow="xl">
          <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
            <Link to={"/"}>
              <Box>
                <Image src={logo} height="200px" p={2} />
              </Box>
            </Link>
            <Flex alignItems={"center"}>
              <Stack direction={"row"} spacing={7}>
                {user.email ? (
                  <Menu>
                    <MenuButton
                      as={Button}
                      rounded={"full"}
                      variant={"link"}
                      cursor={"pointer"}
                      minW={0}
                    >
                      <GiHamburgerMenu
                        fontSize={"26px"}
                        color={"rgba(0,191,115)"}
                      />
                    </MenuButton>
                    <MenuList alignItems={"center"}>
                      <br />
                      <Center>
                        <Avatar
                          size={"xl"}
                          src={profilePhoto || defaultAvatar}
                        />
                      </Center>
                      <br />
                      <Center>
                        <Text as="b" color={"eira.1"}>
                          {user.name.split(" ").slice(0, 2).join(" ")}
                        </Text>
                      </Center>
                      <Center mt={2}>
                        <Button
                          size={"xs"}
                          onClick={logout}
                          color={"eira.1"}
                          bg={"eira.2.2"}
                          _hover={{ bg: "eira.2" }}
                        >
                          Salir
                        </Button>
                      </Center>
                      <br />
                      <MenuDivider />
                      <Link to={"/"}>
                        <MenuItem color={"eira.1"} _hover={{ bg: "eira.2" }}>
                          Inicio
                        </MenuItem>
                      </Link>
                      <Link to={"/pacientes"}>
                        <MenuItem color={"eira.1"} _hover={{ bg: "eira.2" }}>
                          Mis Pacientes
                        </MenuItem>
                      </Link>
                      <Link to={"/consultas"}>
                        <MenuItem color={"eira.1"} _hover={{ bg: "eira.2" }}>
                          Mis Consultas
                        </MenuItem>
                      </Link>
                    </MenuList>
                  </Menu>
                ) : (
                  <></>
                )}
              </Stack>
            </Flex>
          </Flex>
        </Box>
      )}
    </>
  );
}
