import { Avatar, Badge, List, ListItem } from "@chakra-ui/react";
import avatarReal from "../../assets/logotipos/Isotipo-Color.jpg";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const PatientDetail = ({ user }) => {
  const doctors = useSelector((state) => state.doctors);
  let medicoTratante = [];

  if (user && user.doctor) {
    medicoTratante = doctors.filter((doctor) =>
      user.doctor.includes(doctor._id)
    );
  }

  return (
    <List spacing={3}>
      <ListItem>
        <Avatar size={"xs"} src={avatarReal} mr={"2"} />
        Alergias:
        {user.alergias.map((aler) => (
          <h1>{aler}</h1>
        ))}
      </ListItem>

      <ListItem>
        <Avatar size={"xs"} src={avatarReal} mr={"2"} />
        Medicos Tratantes:
        {medicoTratante.map((medico) => (
          <Link to={"/medicos"} key={medico._id}>
            <Badge bg={"eira.2.6"} mx={2} color={"white"}>
              {medico.name}
            </Badge>
          </Link>
        ))}
      </ListItem>
    </List>
  );
};

export default PatientDetail;
