import React, { useEffect, useState } from "react";
import { Button, Grid, Flex, Box, Center } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { createPatient } from "../../store/reducers/patient.reducer";
import { getPatientsByDoctor } from "../../store/reducers/patientsByDoctor.reducer";
import { useForm } from "react-hook-form";
import CreateUserForm from "../user/CreateUserForm";
import API_BASE_URL from "../../utils/apiBaseURL";
import axios from "axios";
import AntecedentesFamiliaresForm from "./AntecedentesFamiliaresForm";

const CreatePatient = ({ path, onClose }) => {
  const { handleSubmit, reset, register } = useForm();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [antecedentes, setAntecedentes] = useState([]);
  const [checkedValuesFamiliar, setCheckedValuesFamiliar] = useState([]);

  useEffect(() => {
    const fetchAntecedentes = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/utils/antecedentes/all`
        );
        setAntecedentes(response.data);
      } catch (error) {
        console.error("Error fetching antecedentes:", error);
      }
    };
    fetchAntecedentes();
  }, []);

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("identificacion", data.identificacion);
    formData.append("dateOfBirth", data.dateOfBirth);
    formData.append("gender", data.gender);
    formData.append("phone", data.phone);
    formData.append("state", data.state);
    formData.append("city", data.city);
    formData.append("address", data.address);
    if (checkedValuesFamiliar.length > 0) {
      formData.append("antecedentes", checkedValuesFamiliar);
    }
    formData.append("role", "patient");
    if (user.role === "doctor") {
      formData.append("doctor", JSON.stringify(user));
    }

    const resp = await dispatch(createPatient(formData));

    if (resp) {
      dispatch(getPatientsByDoctor(user._id));
      reset();
      onClose();
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex align="center" justify="center">
          <Box w="100%">
            <Grid
              templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
              gap={6}
              mx="auto"
              py={4}
              px={2}
            >
              <CreateUserForm register={register} />
              <AntecedentesFamiliaresForm
                register={register}
                data={antecedentes}
                checkedValues={checkedValuesFamiliar}
                setCheckedValues={setCheckedValuesFamiliar}
              />
            </Grid>
          </Box>
        </Flex>
        <Center my={5}>
          <Button
            type="submit"
            bg={"eira.1"}
            color={"white"}
            className="animateFloat"
            _hover={{
              bg: "eira.1",
            }}
          >
            Crear nuevo {path}
          </Button>
        </Center>
      </form>
    </>
  );
};

export default CreatePatient;
