import React, { useState } from "react";
import {
  Center,
  Flex,
  IconButton,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import PaginationCitas from "../../../commons/PaginationCitas";
import CreateCitas from "../GestCitas/CreateCitas";
import { Link, useLocation } from "react-router-dom";
import { deleteCita } from "../../../store/reducers/citas.reducer";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { RiDeleteBin5Line } from "react-icons/ri";
import { FaStethoscope } from "react-icons/fa";

const TableCitas = ({ data }) => {
  const path = useLocation();
  const dispatch = useDispatch();
  const ItemsPerPage = 3;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(-2);
    return `${day}/${month}/${year}`;
  };

  const filteredData = data.slice(
    (currentPage - 1) * ItemsPerPage,
    currentPage * ItemsPerPage
  );

  const deleteUserCita = (item) => {
    dispatch(deleteCita(item._id));
  };

  const confirmDelete = async (item) => {
    const confirm = await Swal.fire({
      title: `Deseas eliminar la cita?`,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      allowOutsideClick: false,
      cancelButtonColor: "red",
    });

    if (confirm.isConfirmed) {
      deleteUserCita(item);
    }
  };

  return (
    <>
      <Table>
        <Thead>
          <Tr textAlign={"center"}>
            {path.pathname.length < 2 && (
              <Th color={"eira.2"} textAlign={"center"}>
                Nombre
              </Th>
            )}
            <Th color={"eira.2"} textAlign={"center"}>
              Cita
            </Th>
            <Th color={"eira.2"} textAlign={"center"}>
              Acción
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredData.map((item, index) => {
            return (
              <Tr key={index}>
                <Th textAlign={"center"}>
                  {path.pathname.length < 2 && (
                    <Link to={`/paciente/${item.paciente._id}`}>
                      {item.paciente.name}
                    </Link>
                  )}
                </Th>

                <Th textAlign={"center"}>
                  {formatDateTime(item.fecha) + " " + item.hora}
                </Th>

                <Th>
                  <Center>
                    <Flex>
                      <Link
                        to={`/consulta/${item.paciente._id}/${data[0]._id}`}
                      >
                        <IconButton
                          size={"xs"}
                          bg={"transparent"}
                          icon={
                            <FaStethoscope
                              fontSize={"25px"}
                              color="rgba(0,191,115)"
                            />
                          }
                        />
                      </Link>
                      <IconButton
                        ml={4}
                        size={"xs"}
                        bg={"transparent"}
                        onClick={() => confirmDelete(item)}
                        icon={
                          <RiDeleteBin5Line fontSize={"25px"} color="red" />
                        }
                      />
                    </Flex>
                  </Center>
                </Th>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <PaginationCitas
        data={data}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        ItemsPerPage={ItemsPerPage}
      />
    </>
  );
};

export default TableCitas;
