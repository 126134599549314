import axios from "axios";
import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import API_BASE_URL from "../../utils/apiBaseURL";

const initialState = [];

export const setConsulta = createAction("SET_CONSULTA");

export const createConsulta = createAsyncThunk(
  "CREATE_CONSULTA",
  async (consultaData) => {
    const response = await axios.post(
      `${API_BASE_URL}/api/consultas`,
      consultaData
    );
    return response.data;
  }
);

export const getAllConsultas = createAsyncThunk("GET_CONSULTAS", async () => {
  const response = await axios.get(`${API_BASE_URL}/api/consultas`);
  return response.data;
});

export const getConsultaById = createAsyncThunk(
  "GET_CONSULTA",
  async (consultaId) => {
    const response = await axios.get(
      `${API_BASE_URL}/api/consultas/${consultaId}`
    );
    return response.data;
  }
);

export const updateConsulta = createAsyncThunk(
  "UPDATE_CONSULTA",
  async (data) => {
    const response = await axios.put(
      `${API_BASE_URL}/api/consultas/${data.id}`,
      data
    );
    return response.data;
  }
);

export const deleteConsulta = createAsyncThunk(
  "DELETE_CONSULTA",
  async (consultaId) => {
    const response = await axios.delete(
      `${API_BASE_URL}/api/consultas/${consultaId}`
    );
    return response.data;
  }
);

export const getConsultasByDoctor = createAsyncThunk(
  "GET_CONSULTAS_BY_DOCTOR",
  async (doctorId) => {
    const response = await axios.get(
      `${API_BASE_URL}/api/consultas/doctores/${doctorId}`
    );
    return response.data;
  }
);

export const getConsultasByPatient = createAsyncThunk(
  "GET_CONSULTAS_BY_PATIENT",
  async (patientId) => {
    const response = await axios.get(
      `${API_BASE_URL}/api/consultas/pacientes/${patientId}`
    );
    return response.data;
  }
);

const consultaReducer = createReducer(initialState, {
  [getAllConsultas.fulfilled]: (state, action) => action.payload,
  [deleteConsulta.fulfilled]: (state, action) => {
    const { message } = action.payload;
    if (message === "Consulta eliminada correctamente") {
      const filteredConsultas = state.filter(
        (consulta) => consulta._id !== action.meta.arg
      );
      return filteredConsultas;
    } else {
      return state;
    }
  },
  [createConsulta.fulfilled]: (state, action) => {
    state.push(action.payload);
  },
  [setConsulta]: (state, action) => action.payload,
  [getConsultaById.fulfilled]: (state, action) => action.payload,
  [updateConsulta.fulfilled]: (state, action) => {
    const updatedConsulta = action.payload;
    const index = state.findIndex(
      (consulta) => consulta._id === updatedConsulta._id
    );
    if (index !== -1) {
      state[index] = updatedConsulta;
    }
  },
  [getConsultasByDoctor.fulfilled]: (state, action) => action.payload,
  [getConsultasByPatient.fulfilled]: (state, action) => action.payload,
});

export default consultaReducer;
