import React from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import API_BASE_URL from "../../../utils/apiBaseURL";
import defaultAvatar from "../../../assets/userO.png";

const FinalizarConsulta = ({ consulta, onSubmit, paciente}) => {
  let profilePhoto = `${API_BASE_URL}/api/utils/uploads/profilePhoto/${paciente.profilePhoto}`;
  const fecha = new Date(paciente.dateOfBirth);
  const anio = fecha.getFullYear();
  const edad = 2023 - anio;

  return (
    <Box>
      <Box>
        <Center>
          <Avatar size={"xl"} src={profilePhoto || defaultAvatar} />
        </Center>
        <Text mt={2} color={"eira.2"} fontSize={"xl"} textAlign={"center"}>
          {paciente.name}
        </Text>
        <Text color={"eira.2"} fontSize={"xl"} textAlign={"center"}>
          Edad: {edad}
        </Text>
        <Flex flexWrap="wrap" justifyContent="center" textAlign="center" mt={4}>
          <Text color={"eira.1"} mx={1}>
            Temp: {consulta.variables.temperatura}
          </Text>
          <Text color={"eira.1"} mx={1}>
            Pulso: {consulta.variables.pulso}
          </Text>
          <Text color={"eira.1"} mx={1}>
            Resp: {consulta.variables.respiracion}
          </Text>
          <Text color={"eira.1"} mx={1}>
            Tensión: {consulta.variables.tensionArterialMN}/
            {consulta.variables.tensionArterialMX}
          </Text>
        </Flex>
      </Box>

      <Heading textAlign={"center"} color={"eira.2"} size={"lg"} mt={10}>
        Consulta
      </Heading>
      <Center>
        {consulta.consulta.motivoConsulta.length > 0 && (
          <Box maxWidth={600} mx={2}>
            <Text color="eira.2" fontSize="xl" mb={2} textAlign="center">
              Motivo de consulta
            </Text>
            <Wrap justify="center">
              {consulta.consulta.motivoConsulta.map((mot, index) => (
                <WrapItem key={index}>
                  <Badge
                    variant="outline"
                    colorScheme="purple"
                    mx={1}
                    color="eira.1"
                  >
                    {mot}
                  </Badge>
                </WrapItem>
              ))}
            </Wrap>
          </Box>
        )}
      </Center>
      {consulta.consulta.enfermedadActual.length > 0 && (
        <Box mt={10} mx={2} maxWidth={280} alignSelf="center">
          <Text color="eira.2" fontSize="xl" mb={2} textAlign="center">
            Enfermedad Actual
          </Text>
          <Box overflow="hidden">
            <Text textAlign="center">{consulta.consulta.enfermedadActual}</Text>
          </Box>
        </Box>
      )}
      {consulta.consulta.diagnosticoConsulta.length > 0 && (
        <Box mt={10} maxWidth={280} alignSelf="center">
          <Text color="eira.2" fontSize="xl" mb={2} textAlign="center">
            Diagnóstico
          </Text>
          <Box overflow="hidden">
            <Text textAlign="center">
              {consulta.consulta.diagnosticoConsulta}
            </Text>
          </Box>
        </Box>
      )}

      {consulta.examenes.length > 0 && (
        <Box mt={10}>
          <Text color={"eira.2"} fontSize={"xl"} mb={2} textAlign={"center"}>
            Examenes a realizar
          </Text>
          <Flex flexWrap="wrap" justifyContent="center" textAlign="center">
            {consulta.examenes.map((mot, index) => (
              <Badge
                key={index}
                variant="outline"
                colorScheme="purple"
                mx={2}
                my={1}
                color={"eira.1"}
              >
                {mot}
              </Badge>
            ))}
          </Flex>
        </Box>
      )}
      {consulta.medicamentos.length > 0 && (
        <Box mt={10}>
          <Text color={"eira.2"} fontSize={"xl"} mb={2} textAlign={"center"}>
            Medicamentos
          </Text>
          {consulta.medicamentos.map((med, index) => (
            <Flex
              key={index}
              flexDir={"column"}
              border={"1px"}
              borderColor={"eira.2"}
              borderRadius={"xl"}
              mt={2}
              p={2}
            >
              <Text textAlign="center" style={{ wordWrap: "break-word" }}>
                <span style={{ color: "rgba(0, 191, 115)" }}>
                  Medicamento:{" "}
                </span>
                {med.medicamento}
              </Text>

              <Text textAlign="center">
                <span style={{ color: "rgba(0,191,115)" }}>Dosis: </span>{" "}
                {med.dosis}
              </Text>
              <Text textAlign="center">
                <span style={{ color: "rgba(0,191,115)" }}>Frecuencia: </span>{" "}
                {med.frecuencia}
              </Text>
            </Flex>
          ))}
        </Box>
      )}

      <Center>
        <Button
          mt={10}
          mb={10}
          type="submit"
          bg={"eira.1"}
          color={"white"}
          className="animateFloat"
          _hover={{
            bg: "eira.1",
          }}
          onClick={onSubmit}
        >
          Finalizar
        </Button>
      </Center>
    </Box>
  );
};

export default FinalizarConsulta;
