import {
  Container,
  Flex,
  Box,
  Heading,
  IconButton,
  Button,
  VStack,
  HStack,
  Wrap,
  WrapItem,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Center,
  Link,
  useMediaQuery,
  useColorModeValue,
  Text,
  Image,
} from "@chakra-ui/react";
import { MdPhone, MdEmail, MdLocationOn } from "react-icons/md";
import { BsInstagram } from "react-icons/bs";
import { TbWorld } from "react-icons/tb";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { Link as ReactRouterLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginRequest } from "../store/reducers/user.reducer";
import Toast from "../commons/Toast";
import { getStates } from "../utils/getStatesReducers";
import logo from "../assets/logotipos/LogoOriginal.png";

export default function Login() {
  const dispatch = useDispatch();
  const [isDesktop] = useMediaQuery("(min-width: 800px)");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit } = useForm();

  const onSubmit = async (e) => {
    const { email, password } = e;
    setLoading(true);
    if (email && password) {
      const response = await dispatch(loginRequest(e));
      const user = response.payload.user;

      if (!user) {
        Toast.fire({
          icon: "error",
          title: `${response.payload}`,
        });
        setLoading(false);
      }
      if (!user.isActivated) {
        navigate("/createPassword");
      } else {
        getStates(user, dispatch);
      }
      setLoading(false);
    }
  };

  return (
    <Container maxW="full" centerContent overflow="hidden">
      <Image
        src={logo}
        height="100px"
        p={2}
        mt={!isDesktop && 4}
        mb={!isDesktop && 6}
      />
      <Flex>
        <Box
          color="gray.700"
          borderRadius="xl"
          border="1px solid #cbd5e0"
          p={{ sm: 5, md: 5, lg: 16 }}
          shadow="xl"
          bg={"whiteAlpha.800"}
        >
          <Box p={4}>
            <Wrap spacing={{ base: 20, sm: 3, md: 5, lg: 20 }}>
              {isDesktop ? (
                <>
                  <WrapItem>
                    <Box>
                      <Heading color={"eira.1"}>CONTACT </Heading>

                      <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                        <VStack pl={0} spacing={3} alignItems="flex-start">
                          <Button
                            size="md"
                            height="48px"
                            width="200px"
                            variant="ghost"
                            color="eira.2"
                            leftIcon={<MdPhone size="30px" />}
                          >
                            (855) 442-7107
                          </Button>
                          <Button
                            size="md"
                            height="48px"
                            width="200px"
                            variant="ghost"
                            color="eira.2"
                            leftIcon={<MdEmail size="30px" />}
                          >
                            hello@eiraplus.com
                          </Button>
                          <Button
                            size="md"
                            height="48px"
                            width="200px"
                            variant="ghost"
                            color="eira.2"
                            leftIcon={<MdLocationOn size="22px" />}
                          >
                            Blvd Suite 101, Anaheim
                          </Button>
                        </VStack>
                      </Box>
                    </Box>
                  </WrapItem>
                </>
              ) : (
                <></>
              )}

              <WrapItem>
                <Box borderRadius="lg" shadow="lg">
                  <Box m={8}>
                    <VStack spacing={5}>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <FormControl id="email" isRequired>
                          <FormLabel color={"eira.1"}>Email address</FormLabel>
                          <Input type="email" {...register("email")} />
                        </FormControl>

                        <FormControl id="password" isRequired py={7}>
                          <FormLabel color={"eira.1"}>Password</FormLabel>
                          <Input type="password" {...register("password")} />
                        </FormControl>
                        <Stack
                          direction={{ base: "column", sm: "row" }}
                          align={"start"}
                          justify={"space-between"}
                        >
                          <ReactRouterLink to={"/reset-password"}>
                            <Text
                              fontSize={"sm"}
                              color={"eira.1"}
                              _hover={{
                                color: "eira.2",
                              }}
                            >
                              Forgot Password?
                            </Text>
                          </ReactRouterLink>
                        </Stack>
                        <Center>
                          <Button
                            mt={5}
                            mb={2}
                            isLoading={loading}
                            type="submit"
                            bg={useColorModeValue("eira.1", "eira.2")}
                            color={"gray.100"}
                            className="animateFloat"
                            _hover={{
                              bg: "eira.1",
                            }}
                          >
                            Login
                          </Button>
                        </Center>
                      </form>

                      <HStack mt={20} spacing={5} alignItems="center">
                        <Link
                          href="https://www.instagram.com/eira.plus/"
                          isExternal
                        >
                          <IconButton
                            aria-label="github"
                            variant="ghost"
                            size="lg"
                            isRound={true}
                            _hover={{ bg: "eira.2.5" }}
                            icon={
                              <BsInstagram
                                size="28px"
                                color="rgba(0,167,170)"
                              />
                            }
                          />
                        </Link>
                        <Link href="https://eiraplus.com" isExternal>
                          <IconButton
                            aria-label="github"
                            variant="ghost"
                            size="lg"
                            isRound={true}
                            _hover={{ bg: "eira.2.5" }}
                            icon={
                              <TbWorld size="35px" color="rgba(0,167,170)" />
                            }
                          />
                        </Link>
                      </HStack>
                    </VStack>
                  </Box>
                </Box>
              </WrapItem>
            </Wrap>
          </Box>
        </Box>
      </Flex>
    </Container>
  );
}
