import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Container,
  Flex,
  Heading,
  VStack,
  Wrap,
  Link,
  Box,
  HStack,
  useMediaQuery,
  WrapItem,
  IconButton,
  Center,
  Text,
  Image,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { MdPhone, MdEmail, MdLocationOn, MdFacebook } from "react-icons/md";
import { BsGithub, BsDiscord } from "react-icons/bs";
import { TbWorld } from "react-icons/tb";
import { useForm } from "react-hook-form";
import { forgotPassRequest } from "../store/reducers/user.reducer";
import { useDispatch } from "react-redux";
import { handleAddUser } from "../commons/ToastFire";
import logo from "../assets/logotipos/LogoOriginal.png";
const ForgotPassword = () => {
  const { register, handleSubmit } = useForm();
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const forgotPass = async (e) => {
    const { email } = e;
        const resp = await dispatch(forgotPassRequest(email));
    navigate("/");
    handleAddUser(resp, null, null, null);
  };

  return (
    <Container maxW="full" mt={10} centerContent overflow="hidden">
      <Image
        src={logo}
        height="100px"
        p={2}
        mt={!isLargerThan800 && 4}
        mb={!isLargerThan800 && 6}
      />
      <Flex>
        <Box
          bg="white"
          color="gray.700"
          borderRadius="xl"
          border="1px solid #cbd5e0"
          m={{ sm: 4, md: 16, lg: 10 }}
          p={{ sm: 5, md: 5, lg: 16 }}
          shadow="xl"
        >
          <Box p={4}>
            <Wrap spacing={{ base: 20, sm: 3, md: 5, lg: 20 }}>
              {isLargerThan800 ? (
                <>
                  <WrapItem>
                    <Box>
                      <Heading color={"eira.1"}>CONTACT US!</Heading>

                      <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                        <VStack pl={0} spacing={3} alignItems="flex-start">
                          <Button
                            size="md"
                            height="48px"
                            width="200px"
                            variant="ghost"
                            color="gray.600"
                            leftIcon={
                              <MdPhone color="rgba(0,167,170)" size="30px" />
                            }
                          >
                            (855) 442-7107
                          </Button>
                          <Button
                            size="md"
                            height="48px"
                            width="200px"
                            variant="ghost"
                            color="gray.600"
                            leftIcon={
                              <MdEmail color="rgba(0,167,170)" size="30px" />
                            }
                          >
                            hello@eira-plus.com
                          </Button>
                          <Button
                            size="md"
                            height="48px"
                            width="200px"
                            variant="ghost"
                            color="gray.600"
                            leftIcon={
                              <MdLocationOn
                                color="rgba(0,167,170)"
                                size="22px"
                              />
                            }
                          >
                            Blvd Suite 101, Anaheim
                          </Button>
                          <Link
                            href="https://eira-plus.com/contact-us"
                            isExternal
                          >
                            <Button
                              size="md"
                              height="48px"
                              width="200px"
                              variant="ghost"
                              color="gray.600"
                              leftIcon={
                                <TbWorld color="rgba(0,167,170)" size="30px" />
                              }
                            >
                              www.eira-plus.com
                            </Button>
                          </Link>
                        </VStack>
                      </Box>
                      <HStack
                        mt={{ lg: 10, md: 10 }}
                        spacing={5}
                        px={5}
                        alignItems="flex-start"
                      >
                        <IconButton
                          aria-label="facebook"
                          variant="ghost"
                          size="lg"
                          isRound={true}
                          _hover={{ bg: "eira.2.1" }}
                          icon={
                            <MdFacebook size="28px" color="rgba(0,167,170)" />
                          }
                        />
                        <IconButton
                          aria-label="github"
                          variant="ghost"
                          size="lg"
                          isRound={true}
                          _hover={{ bg: "eira.2.1" }}
                          icon={
                            <BsGithub size="28px" color="rgba(0,167,170)" />
                          }
                        />
                        <IconButton
                          aria-label="discord"
                          variant="ghost"
                          size="lg"
                          isRound={true}
                          _hover={{ bg: "eira.2.1" }}
                          icon={
                            <BsDiscord size="28px" color="rgba(0,167,170)" />
                          }
                        />
                      </HStack>
                    </Box>
                  </WrapItem>
                </>
              ) : (
                <></>
              )}
              <WrapItem>
                <Box bg="whiteAlpha.500" borderRadius="lg" shadow="lg">
                  <Box m={8} color="#0B0E3F">
                    <VStack spacing={5}>
                      <Heading size={"xl"} mb={"10"} color={"eira.1"}>
                        Restablecer Contraseña
                      </Heading>
                      <form onSubmit={handleSubmit(forgotPass)}>
                        <FormControl id="email" isRequired>
                          <FormLabel color={"eira.1"}>Email</FormLabel>
                          <Input type="email" {...register("email")} />
                        </FormControl>
                        <Text color={"eira.2"}>
                          Te enviaremos las instrucciones a tu email
                        </Text>
                        <Center>
                          <Button
                            mt={12}
                            type="submit"
                            bg={"eira.1"}
                            color={"gray.100"}
                            _hover={{
                              bg: "eira.1.5",
                            }}
                          >
                            Restablecer
                          </Button>
                        </Center>
                      </form>
                    </VStack>
                  </Box>
                </Box>
              </WrapItem>
            </Wrap>
          </Box>
        </Box>
      </Flex>
    </Container>
  );
};

export default ForgotPassword;
