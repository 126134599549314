import React from "react";
import { useSelector } from "react-redux";
import MiniStatistics from "../../commons/MiniStatistics";
import { Flex, SimpleGrid } from "@chakra-ui/react";
import { FiUserCheck } from "react-icons/fi";
import { BsCalendarCheck } from "react-icons/bs";
import { FaStethoscope } from "react-icons/fa";

const DoctorStatistic = () => {
  const patientsByDoctor = useSelector((state) => state.patientsByDoctor);
  const totalPacientes = useSelector((state) => state.patients);
  const citas = useSelector((state) => state.citas);
  const consultas = useSelector((state) => state.consultas);

  return (
    <SimpleGrid columns={{ base: 2, md: 2, lg: 4 }} gap="20px" mb="20px" px={0}>
      <MiniStatistics
        path="/pacientes"
        startContent={
          <Flex
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
            w="56px"
            h="56px"
            bg="eira.2"
          >
            <FiUserCheck fontSize="40px" color="white" />
          </Flex>
        }
        name="Tus pacientes"
        value={patientsByDoctor.length}
        description={totalPacientes.length}
      />
      <MiniStatistics
        startContent={
          <Flex
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
            w="56px"
            h="56px"
            bg="eira.2"
          >
            <BsCalendarCheck fontSize="35px" color="white" />
          </Flex>
        }
        name="Citas Pendientes"
        value={citas.length}
      />
      <MiniStatistics
        path="/consultas"
        startContent={
          <Flex
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
            w="56px"
            h="56px"
            bg="eira.2"
          >
            <FaStethoscope fontSize="40px" color="white" />
          </Flex>
        }
        name="Mis Consultas"
        value={consultas.length}
        growth="descripcion"
      />
    </SimpleGrid>
  );
};

export default DoctorStatistic;
