import React from "react";
import {
  Center,
  Flex,
  IconButton,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { deleteCita } from "../../../store/reducers/citas.reducer";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { MdDelete } from "react-icons/md";
import { FaStethoscope } from "react-icons/fa";

const TableCitas = ({ data }) => {
  const dispatch = useDispatch();

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(-2);
    return `${day}/${month}/${year}`;
  };

  const deleteUserCita = (item) => {
    dispatch(deleteCita(item._id));
  };
  const confirmDelete = async (item) => {
    const confirm = await Swal.fire({
      title: `Deseas eliminar la cita?`,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      allowOutsideClick: false,
      cancelButtonColor: "red",
    });

    if (confirm.isConfirmed) {
      deleteUserCita(item);
    }
  };

  return (
    <>
      <Table>
        <Thead>
          <Tr textAlign={"center"}>
            <Th color={"eira.2"} textAlign={"center"}>
              Nombre
            </Th>

            <Th color={"eira.2"} textAlign={"center"}>
              Cita
            </Th>
            <Th color={"eira.2"} textAlign={"center"}>
              Acción
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => {
            return (
              <Tr key={index}>
                <Th textAlign={"center"}>{item.paciente.name}</Th>

                <Th textAlign={"center"}>
                  {formatDateTime(item.fecha) + " " + item.hora}
                </Th>

                <Th>
                  <Center>
                    <Flex>
                      <Link to={`/consulta/${item.paciente._id}/${item._id}`}>
                        <IconButton
                          size={"xs"}
                          bg={"transparent"}
                          icon={
                            <FaStethoscope
                              fontSize={"22px"}
                              color="rgba(0,191,115)"
                            />
                          }
                        />
                      </Link>
                      <IconButton
                        ml={2}
                        size={"xs"}
                        bg={"transparent"}
                        onClick={() => confirmDelete(item)}
                        icon={<MdDelete fontSize={"25px"} color="red" />}
                      />
                    </Flex>
                  </Center>
                </Th>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </>
  );
};

export default TableCitas;
