import React from "react";
import TableCitasByDoctor from "./TableCitasByDoctor";
import { Box } from "@chakra-ui/react";
import PatientStatitics from "./PatientStatitics";
const HomePatient = () => {
  return (
    <Box mt={10} px={10}>
      <PatientStatitics/>
      <TableCitasByDoctor />
    </Box>
  );
};

export default HomePatient;
