import React from "react";
import { useSelector } from "react-redux";
import MiniStatistics from "../../commons/MiniStatistics";
import { Flex, SimpleGrid } from "@chakra-ui/react";
import { FiUserCheck } from "react-icons/fi";
import { RiUserSettingsLine } from "react-icons/ri";
import { FiUsers } from "react-icons/fi";
import { BiBriefcaseAlt } from "react-icons/bi";
import { GiMedicines } from "react-icons/gi";

const Statistic = () => {
  const patients = useSelector((state) => state.patients);
  const doctors = useSelector((state) => state.doctors);
  const medicines = useSelector((state) => state.medicines);
  const specialities = useSelector((state) => state.specialities);
  const admins = useSelector((state) => state.admins);
  return (
    <SimpleGrid
      columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
      gap="40px"
      mb="20px"
    >
      <MiniStatistics
        path={"/pacientes"}
        startContent={
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"50%"}
            w="56px"
            h="56px"
            bg="eira.2"
          >
            <FiUserCheck fontSize={"40px"} color="white" />
          </Flex>
        }
        name="Pacientes"
        value={patients.length}
        growth="descripcion"
      />
      <MiniStatistics
        path={"/medicos"}
        startContent={
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"50%"}
            w="56px"
            h="56px"
            bg="eira.2"
          >
            <FiUsers fontSize={"40px"} color="white" />
          </Flex>
        }
        name="Doctores"
        value={doctors.length}
        growth="descripcion"
      />
      <MiniStatistics
        path={"/admin"}
        startContent={
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"50%"}
            w="56px"
            h="56px"
            bg="eira.2"
          >
            <RiUserSettingsLine fontSize={"40px"} color="white" />
          </Flex>
        }
        name="Administradores"
        value={admins.length}
        growth="descripcion"
      />
      <MiniStatistics
        path={"/medicinas"}
        startContent={
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"50%"}
            w="56px"
            h="56px"
            bg="eira.2"
          >
            <GiMedicines fontSize={"40px"} color="white" />
          </Flex>
        }
        name="Medicinas"
        value={medicines.length}
        growth="descripcion"
      />
      <MiniStatistics
        path={"/especialidades"}
        startContent={
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"50%"}
            w="56px"
            h="56px"
            bg="eira.2"
          >
            <BiBriefcaseAlt fontSize={"40px"} color="white" />
          </Flex>
        }
        name="Especialidades"
        value={specialities.length}
        growth="descripcion"
      />
    </SimpleGrid>
  );
};

export default Statistic;
