import React from "react";
import {
  FormLabel,
  FormControl,
  Input,
  Flex,
  Stack,
  Box,
  Button,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { createSpeciality } from "../../store/reducers/speciality.reducer";
import { useDispatch } from "react-redux";
import { handleAddUser } from "../../commons/ToastFire";

const CreateSpeciality = ({ path, onClose }) => {
  const { handleSubmit, reset, register } = useForm();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    const resp = await dispatch(createSpeciality(data));
    handleAddUser(resp, data, reset, onClose);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex align="center" justify="center">
          <Stack spacing={8} mx="auto" maxW="lg" py={4} px={2}>
            <Box rounded="lg">
              <Stack spacing={2}>
                <FormControl isRequired>
                  <FormLabel>Nombre</FormLabel>
                  <Input {...register("name")} />
                </FormControl>

                <Stack spacing={10}>
                  <Stack
                    direction={{ base: "column", sm: "row" }}
                    align={"start"}
                    justify={"space-between"}
                  ></Stack>
                  <Button
                    type="submit"
                    bg={"eira.1"}
                    color={"white"}
                    _hover={{
                      bg: "eira.1.5",
                    }}
                  >
                    Crear nuevo {path}
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Flex>
      </form>
    </>
  );
};

export default CreateSpeciality;
