import { Button, Table, Tbody, Text, Th, Thead, Tr } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";

const TableConsultasInfoDetail = ({ consultasDelPaciente }) => {
  const formatDateAndTime = (dateTime) => {
    return format(new Date(dateTime), "dd/MM/yy HH:mm");
  };

  return (
    <Table>
      <Thead>
        <Tr textAlign={"center"}>
          <Th color={"eira.2"} textAlign={"center"}>
            Fecha
          </Th>
          <Th color={"eira.2"} textAlign={"center"}>
            Acción
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {consultasDelPaciente.map((item, index) => (
          <Tr key={index}>
            <Th textAlign={"center"}>
              <Text>{formatDateAndTime(item.date)}</Text>
            </Th>
            <Th textAlign={"center"}>
              <Link to={`/detalle-consulta/${item._id}`}>
                <Button
                  size={"xs"}
                  bg={"eira.2"}
                  mx={1}
                  _hover={{ bg: "eira.2.2" }}
                  color={"white"}
                >
                  Ver Detalles
                </Button>
              </Link>
            </Th>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};

export default TableConsultasInfoDetail;
