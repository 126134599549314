import { FormControl, Input, Select, Text } from "@chakra-ui/react";
import React, { useState } from "react";
const venezuelaData = require("../../utils/states-cities-venezuela.json");

const CreateUserForm = ({ register }) => {
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [cities, setCities] = useState([]);
  const states = Object.keys(venezuelaData);

  const handleStateChange = (event) => {
    const stateName = event.target.value;
    setSelectedState(stateName);
    setCities(venezuelaData[stateName]);
  };

  const handleCityChange = (event) => {
    const cityName = event.target.value;
    setSelectedCity(cityName);
  };

  return (
    <>
      <FormControl isRequired>
        <Text as={"b"} color={"eira.2.7"}>
          Nombre
        </Text>
        <Input
          {...register("name")}
          focusBorderColor="eira.2"
          color={"eira.2"}
        />
      </FormControl>
      <FormControl isRequired>
        <Text as={"b"} color={"eira.2.7"}>
          Cédula de identidad
        </Text>
        <Input
          type="number"
          {...register("identificacion")}
          focusBorderColor="eira.2"
          color={"eira.2"}
        />
      </FormControl>
      <FormControl isRequired>
        <Text as={"b"} color={"eira.2.7"}>
          Fecha de nacimiento
        </Text>
        <Input
          {...register("dateOfBirth")}
          focusBorderColor="eira.2"
          placeholder="Select Date and Time"
          size="md"
          type="date"
          color={"eira.2.8"}
        />
      </FormControl>

      <FormControl>
        <Text as={"b"} color={"eira.2.7"}>
          Genero
        </Text>
        <Select
          {...register("gender")}
          focusBorderColor="eira.2"
          color="eira.2"
        >
          <option value="Masculino">Masculino</option>
          <option value="Femenino">Femenino</option>
          <option value="Otro">Otro</option>
        </Select>
      </FormControl>
      <FormControl isRequired>
        <Text as={"b"} color={"eira.2.7"}>
          Email
        </Text>
        <Input
          {...register("email")}
          type="email"
          focusBorderColor="eira.2"
          color={"eira.2"}
        />
      </FormControl>
      <FormControl isRequired>
        <Text as={"b"} color={"eira.2.7"}>
          Teléfono
        </Text>
        <Input
          type="tel"
          {...register("phone")}
          focusBorderColor="eira.2"
          color={"eira.2"}
        />
      </FormControl>

      <FormControl isRequired>
        <Text as={"b"} color={"eira.2.7"}>
          Estado
        </Text>
        <Select
          {...register("state")}
          focusBorderColor="eira.2"
          placeholder="Seleccione un estado"
          color={"eira.2.8"}
          value={selectedState}
          onChange={handleStateChange}
        >
          {states.map((state) =>
            selectedState === state ? (
              <option key={state} value={state}>
                {state}
              </option>
            ) : (
              <option key={state} value={state}>
                {state}
              </option>
            )
          )}
        </Select>
      </FormControl>
      <FormControl isRequired>
        <Text as={"b"} color={"eira.2.7"}>
          Ciudad
        </Text>
        <Select
          {...register("city")}
          focusBorderColor="eira.2"
          placeholder="Seleccione una ciudad"
          color={"eira.2.8"}
          value={selectedCity}
          onChange={handleCityChange}
          disabled={!selectedState}
        >
          {cities
            ? cities.map((city) => (
                <option key={city} value={city}>
                  {city}
                </option>
              ))
            : []}
        </Select>
      </FormControl>
      <FormControl isRequired>
        <Text as={"b"} color={"eira.2.7"}>
          Dirección
        </Text>
        <Input
          {...register("address")}
          focusBorderColor="eira.2"
          disabled={!selectedCity}
          color={"eira.2.8"}
          _placeholder={{ color: "eira.2.4" }}
          placeholder="Dirección de domicilio"
        />
      </FormControl>
    </>
  );
};

export default CreateUserForm;
