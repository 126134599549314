import axios from "axios";
import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import { endpointUser } from "../../utils/endpoints/apiUser";
const role = "admin";
const initialState = [];

export const setAdmin = createAction("SET_ADMIN");

export const createAdmin = createAsyncThunk(
  "CREATE_ADMIN",
  async (formData) => {
    const response = await axios.post(endpointUser.create(role), formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  }
);

export const getAllAdmin = createAsyncThunk("GET_ADMINS", () => {
  return axios.get(endpointUser.getAll(role)).then((r) => r.data);
});
export const getAdminById = createAsyncThunk("GET_ADMIN", (adminId) => {
  return axios.get(endpointUser.getById(role, adminId)).then((r) => r.data);
});
export const updateAdmin = createAsyncThunk("UPDATE_ADMIN", async (data) => {
  const response = await axios.put(endpointUser.update(role), data);
  return response.data;
});
export const deleteAdmin = createAsyncThunk("DELETE_ADMIN", async (userId) => {
  const response = await axios.delete(endpointUser.delete(role, userId));
  return response.data;
});

const adminReducer = createReducer(initialState, {
  [getAllAdmin.fulfilled]: (state, action) => action.payload,
  [deleteAdmin.fulfilled]: (state, action) => {
    const { acknowledged, deletedCount } = action.payload;
    if (acknowledged && deletedCount === 1) {
      const filteredUsers = state.filter(
        (user) => user._id !== action.meta.arg
      );
      return filteredUsers;
    } else {
      return state;
    }
  },
  [createAdmin.fulfilled]: (state, action) => {
    state.push(action.payload);
  },
  [setAdmin]: (state, action) => action.payload,
  [getAdminById.fulfilled]: (state, action) => action.payload,
  [updateAdmin.fulfilled]: (state, action) => {
    const updatedAdmin = action.payload;
    const index = state.findIndex((admin) => admin._id === updatedAdmin._id);
    if (index !== -1) {
      state[index] = updatedAdmin;
    }
  },
});

export default adminReducer;
