import { FormControl, Input, Text } from "@chakra-ui/react";
import React from "react";

const EditUserForm = ({
  register,
  onChange,
  name,
  _id,
  address,
  phone,
  identificacion,
}) => {
  return (
    <>
      <FormControl isRequired>
        <Text as={"b"} color={"eira.2.7"}>
          Nombre
        </Text>
        <Input
          {...register("name")}
          value={name}
          focusBorderColor="eira.2"
          color={"eira.2"}
          onChange={(e) => onChange(e, _id)}
        />
      </FormControl>
      <FormControl>
        <Text as={"b"} color={"eira.2.7"}>
          Identificación
        </Text>
        <Input
          {...register("identificacion")}
          value={identificacion}
          focusBorderColor="eira.2"
          color={"eira.2"}
          onChange={(e) => onChange(e, _id)}
        />
      </FormControl>

      <FormControl isRequired>
        <Text as={"b"} color={"eira.2.7"}>
          DIrección
        </Text>
        <Input
          {...register("address")}
          focusBorderColor="eira.2"
          color={"eira.2"}
          value={address}
          onChange={(e) => onChange(e, _id)}
        />
      </FormControl>

      <FormControl isRequired>
        <Text as={"b"} color={"eira.2.7"}>
          Teléfono
        </Text>
        <Input
          type={"number"}
          {...register("phone")}
          color={"eira.2"}
          focusBorderColor="eira.2"
          value={phone}
          onChange={(e) => onChange(e, _id)}
        />
      </FormControl>
    </>
  );
};

export default EditUserForm;
