import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Center,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import TableView from "../../commons/TableView";
import axios from "axios";
import API_BASE_URL from "../../utils/apiBaseURL";

const ServiceTabs = () => {
  const specialities = useSelector((state) => state.specialities);
  const medicines = useSelector((state) => state.medicines);
  const [antecedentes, setAntecedentes] = useState([]);

  useEffect(() => {
    const fetchAntecedentes = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/utils/antecedentes/all`
        );
        setAntecedentes(response.data);
      } catch (error) {
        console.error("Error fetching antecedentes:", error);
      }
    };
    fetchAntecedentes();
  }, []);

  const antecedentesPersonales = antecedentes.filter(
    (ant) => ant.tipo === "personal"
  );
  const antecedentesFamiliares = antecedentes.filter(
    (ant) => ant.tipo === "familiar"
  );
  return (
    <Center>
      <Box mt={"40px"}>
        <Tabs variant="soft-rounded" colorScheme="green">
          <Flex>
            <TabList>
              <Tab>Especialidades</Tab>
              <Tab>Medicinas</Tab>
              <Tab>Antecedentes Personales</Tab>
              <Tab>Antecedentes Familiares</Tab>
            </TabList>
          </Flex>
          <Flex width={"800px"} mt={10}>
            <TabPanels>
              <TabPanel>
                <TableView data={specialities} path={"especialidades"} />
              </TabPanel>
              <TabPanel>
                <TableView data={medicines} path={"medicinas"} />
              </TabPanel>
              <TabPanel>
                <TableView
                  data={antecedentesPersonales}
                  path={"antecedentesPersonales"}
                />
              </TabPanel>
              <TabPanel>
                <TableView
                  data={antecedentesFamiliares}
                  path={"antecedentesFamiliares"}
                />
              </TabPanel>
            </TabPanels>
          </Flex>
        </Tabs>
      </Box>
    </Center>
  );
};

export default ServiceTabs;
