import React, { useEffect, useState } from "react";
import { Box, Heading, Text, Flex, Image } from "@chakra-ui/react";
import { HiIdentification } from "react-icons/hi2";
import { FaBirthdayCake, FaPhone, FaUser } from "react-icons/fa";
import { useParams } from "react-router-dom";
import axios from "axios";
import API_BASE_URL from "../../../utils/apiBaseURL";
import medicina from "../../../assets/medicina.png";
import Logo from "../../../assets/logotipos/LogoOriginal.png";
import { MdLocationPin } from "react-icons/md";

const Recipe = () => {
  const [consulta, setConsulta] = useState(null);
  const { consultaId } = useParams();

  useEffect(() => {
    const fetchConsulta = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/consultas/${consultaId}`
        );
        setConsulta(response.data);
      } catch (error) {
        console.error("Error al obtener la consulta:", error);
        setConsulta([]); // Establecer consulta como un array vacío en caso de error
      }
    };

    fetchConsulta();
  }, [consultaId]);

  if (consulta === null) {
    return (
      <Text textAlign="center" color="eira.2" mt={20}>
        Cargando...
      </Text>
    );
  }

  if (consulta.length === 0) {
    return (
      <Text textAlign="center" color="eira.2" mt={20}>
        Consulta no existe
      </Text>
    );
  }

  const fecha = new Date(consulta.paciente.dateOfBirth);
  const dia = fecha.getDate();
  const mes = fecha.getMonth() + 1;
  const anio = fecha.getFullYear();

  return (
    <Box
      maxW="600px"
      mx="auto"
      style={{ minHeight: "100vh", position: "relative" }}
    >
      <Box maxW="600px" mx="auto" p={4} bg={"white"} borderRadius={"2xl"}>
        <Flex align="center" justify="space-between">
          <Box>
            <Heading color="eira.2" size={"md"}>
              Dr. {consulta.doctor.name}
            </Heading>
            <Text fontSize={"xs"} color="rgb(2,91,91)">
              {consulta.doctor.especialidades[0].toUpperCase()}
            </Text>
            <Flex>
              <HiIdentification color="rgb(2,91,91)" fontSize="15px" />
              <Text
                fontSize={"xs"}
                ml={2}
                color="rgb(2,91,91)"
                fontWeight={"bold"}
              >
                V- {consulta.doctor.identificacion}
              </Text>
            </Flex>
            <Flex>
              <FaPhone color="rgb(2,91,91)" fontSize="15px" />
              <Text
                fontSize={"xs"}
                ml={2}
                color="rgb(2,91,91)"
                fontWeight={"bold"}
              >
                {consulta.doctor.phone}
              </Text>
            </Flex>
            <Flex>
              <MdLocationPin color="rgb(2,91,91)" fontSize="15px" />
              <Text
                fontSize={"xs"}
                ml={2}
                color="rgb(2,91,91)"
                fontWeight={"bold"}
              >
                {consulta.doctor.address}
              </Text>
            </Flex>
          </Box>
          <Image src={medicina} height="100px" p={2} />
        </Flex>
      </Box>
      <Box bg="white" p={4} borderRadius="md" boxShadow="lg">
        <Heading color="eira.2" size={"md"} mb={2}>
          Paciente
        </Heading>
        <Flex>
          <FaUser color="rgb(2,91,91)" fontSize="15px" />
          <Text fontSize={"xs"} ml={2} color="rgb(2,91,91)" fontWeight={"bold"}>
            {consulta.paciente.name}
          </Text>
        </Flex>
        <Flex>
          <HiIdentification color="rgb(2,91,91)" fontSize="15px" />
          <Text fontSize={"xs"} ml={2} color="rgb(2,91,91)" fontWeight={"bold"}>
            V- {consulta.paciente.identificacion}
          </Text>
        </Flex>
        <Flex>
          <FaBirthdayCake color="rgb(2,91,91)" fontSize="15px" />
          <Text fontSize={"xs"} ml={2} color="rgb(2,91,91)" fontWeight={"bold"}>
            {`${dia}/${mes}/${anio}`}
          </Text>
        </Flex>

        <Flex>
          <FaPhone color="rgb(2,91,91)" fontSize="15px" />
          <Text fontSize={"xs"} ml={2} color="rgb(2,91,91)" fontWeight={"bold"}>
            {consulta.paciente.phone}
          </Text>
        </Flex>
        <Flex>
          <MdLocationPin color="rgb(2,91,91)" fontSize="15px" />
          <Text fontSize={"xs"} ml={2} color="rgb(2,91,91)" fontWeight={"bold"}>
            {consulta.paciente.address}
          </Text>
        </Flex>
      </Box>
      <Box bg="white" p={4} borderRadius="md" boxShadow="lg">
        <Heading color="eira.2" size={"md"}>
          Recipe Médico
        </Heading>
        {consulta.medicamentos.map((item) => (
          <Box key={item._id} mt={10}>
            <Heading color="rgb(2,91,91)" size={"sm"} textAlign={"center"}>
              {item.medicamento}
            </Heading>
            <Text fontSize="sm" color="rgb(2,91,91)">
              <strong>Dosis:</strong>
              {item.dosis}
            </Text>
            <Text fontSize="sm" color="rgb(2,91,91)">
              <strong>Frecuencia:</strong>
              {item.frecuencia}
            </Text>
          </Box>
        ))}
      </Box>
      <Box
        py={5}
        justify="center"
        align="center"
        boxShadow="lg"
        bg="white"
        borderRadius={"xl"}
      >
        <Image
          src={consulta.doctor.firma}
          borderBottom="1px solid black"
          bg={"white"}
          borderRadius={"2xl"}
        />

        <Text color={"black"} mb={2}>
          {consulta.doctor.name} | IM-{consulta.doctor.identificacionMedica}
        </Text>
      </Box>

      {/* Footer */}
      <Box
        justify="center"
        align="center"
        bg="white"
        color="white"
        boxShadow="lg"
        style={{ position: "flex", bottom: "10", width: "100%" }}
      >
        <Image src={Logo} height="80px" p={2} />
        <Text color={"eira.2 "} fontWeight={"bold"} mb={2}>
          Cuidarse nunca fue tan facil
        </Text>
      </Box>
    </Box>
  );
};

export default Recipe;
