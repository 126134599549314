import { useState } from "react";
import {
  Box,
  FormControl,
  Button,
  Input,
  Text,
  Heading,
} from "@chakra-ui/react";
import { FaTimes } from "react-icons/fa";

const FormularioConsulta = ({ checkedValues, setCheckedValues }) => {
  const [searchText, setSearchText] = useState("");
  const examenesMedicosFrecuentes = [
    "Hemograma completo",
    "Perfil de lípidos",
    "Glucosa en ayunas",
    "Creatinina",
    "Urea",
    "Ácido úrico",
    "Bilirrubina total y directa",
    "Transaminasas (AST/ALT)",
    "Fosfatasa alcalina",
    "Calcio",
    "Ferritina",
    "Hierro sérico",
    "Colesterol total",
    "Triglicéridos",
    "HDL colesterol",
    "LDL colesterol",
    "Proteínas totales y fraccionadas",
    "Electroforesis de proteínas",
    "Amilasa",
    "Lipasa",
    "Glicemia postprandial",
    "Ácido fólico",
    "Vitamina B12",
    "Hormona tiroestimulante (TSH)",
    "T4 libre",
    "T3 total",
    "Hormona luteinizante (LH)",
    "Hormona foliculoestimulante (FSH)",
    "Estradiol",
    "Progesterona",
    "Prolactina",
    "Testosterona total",
    "Testosterona libre",
    "Cortisol",
    "Cortisol salival",
    "Hormona del crecimiento (GH)",
    "Insulina",
    "Anticuerpos antinucleares (ANA)",
    "Factor reumatoide (FR)",
    "Anticuerpos anti-CCP",
    "Proteína C reactiva (PCR)",
    "VSG (velocidad de sedimentación globular)",
    "Troponina I",
    "CK (creatina cinasa)",
    "CK-MB",
    "Tropomiosina",
    "Dímero D",
    "Procalcitonina",
    "Gasometría arterial",
    "Cultivo de sangre",
    "Cultivo de orina",
  ];

  const handleCheckboxChange = (value) => {
    setCheckedValues((prevCheckedValues) =>
      prevCheckedValues.includes(value)
        ? prevCheckedValues.filter((val) => val !== value)
        : [...prevCheckedValues, value]
    );
    setSearchText("");
  };
  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };

  function removeAntecedente(name) {
    const nuevasEspecialidades = checkedValues.filter(
      (especialidad) => especialidad !== name
    );
    setCheckedValues(nuevasEspecialidades);
  }
  const filteredOptions = examenesMedicosFrecuentes
    .filter((option) => option.toLowerCase().includes(searchText.toLowerCase()))
    .slice(0, 3);

  return (
    <Box margin="0 auto">
      <form>
        <FormControl>
          <Heading
            size="lg"
            textAlign="center"
            color="eira.2"
            flex="1"
            mb={10}
            mt={{ base: 0, md: 10 }}
          >
            Examenes
          </Heading>
          <Box>
            <Input
              focusBorderColor="eira.2"
              size={"sm"}
              placeholder="Que examen debe realizar?"
              _placeholder={{ color: "eira.2.4" }}
              color={"eira.2"}
              value={searchText}
              onChange={handleSearchTextChange}
            />
            {searchText && (
              <>
                {filteredOptions.map((option) => (
                  <Text
                    textAlign={"center"}
                    maxW={"50%"}
                    bg={"eira.2.1"}
                    py={1}
                    borderRadius={"2xl"}
                    color={"eira.2"}
                    fontWeight={"bold"}
                    my={1}
                    key={option}
                    _hover={{ cursor: "pointer" }}
                    onClick={() => handleCheckboxChange(option)}
                  >
                    {option}
                  </Text>
                ))}
              </>
            )}
          </Box>
          <Box>
            {checkedValues.map((option) => (
              <Button
                mt={4}
                key={option}
                size={"xs"}
                bg={"eira.2"}
                color={"white"}
                variant="outline"
                textAlign={"center"}
                _hover={{
                  bg: "red.500",
                  color: "white",
                  _before: {
                    display: "inline-block",
                    marginRight: "5px",
                    fontSize: "16px",
                    lineHeight: "1",
                    textAlign: "center",
                    verticalAlign: "middle",
                    fontWeight: "bold",
                    width: "16px",
                    height: "16px",
                    background: "white",
                    borderRadius: "50%",
                    color: "red.500",
                    boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.75)",
                  },
                }}
                onClick={() => removeAntecedente(option)}
              >
                {option}
                <FaTimes
                  style={{ marginLeft: "5px", display: "inline-block" }}
                />
              </Button>
            ))}
          </Box>
        </FormControl>
      </form>
    </Box>
  );
};

export default FormularioConsulta;
