import { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Textarea,
  Button,
  Input,
  Text,
  Heading,
} from "@chakra-ui/react";
import { FaTimes } from "react-icons/fa";

const FormularioConsulta = ({ values, onChange }) => {
  const [searchText, setSearchText] = useState("");
  const [motivoConsulta, setMotivoConsulta] = useState([]);
  const tipos = [
    "Piel",
    "Cabeza",
    "Ojos",
    "Oídos",
    "General",
    "Nariz",
    "Boca",
    "Garganta",
    "Respiratorio",
    "Osteomuscular",
    "Cardiovascular",
    "Gastrointestinal",
    "Genitourinario",
    "Ginecológicos",
    "Nerviosos y mental",
    "Gastrointestinal",
    "Endocrino",
    "Hematológico",
    "Inmunológico",
    "Metabólico",
    "Neurológico",
    "Dermatológico",
    "Reproductivo",
    "Hepático",
    "Renal",
    "Articular",
  ];
  const handleCheckboxChange = (value) => {
    let updatedMotivoConsulta = [...motivoConsulta];

    if (updatedMotivoConsulta.includes(value)) {
      updatedMotivoConsulta = updatedMotivoConsulta.filter(
        (val) => val !== value
      );
    } else {
      updatedMotivoConsulta.push(value);
    }

    setMotivoConsulta(updatedMotivoConsulta);
    setSearchText("");
    onChange(updatedMotivoConsulta, "motivoConsulta");
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };

  function removeAntecedente(name) {
    const nuevasEspecialidades = motivoConsulta.filter(
      (especialidad) => especialidad !== name
    );
    setMotivoConsulta(nuevasEspecialidades);
    onChange(nuevasEspecialidades, "motivoConsulta");
  }

  const filteredOptions = tipos
    .filter((option) => option.toLowerCase().includes(searchText.toLowerCase()))
    .slice(0, 3);

  return (
    <Box margin="0 auto">
      <Heading
        size="lg"
        textAlign="center"
        color="eira.2"
        flex="1"
        mb={10}
        mt={{ base: 0, md: 10 }}
      >
        Consulta
      </Heading>
      <form>
        <FormControl mb={4}>
          <FormLabel color={"eira.1"}>Motivo de Consulta</FormLabel>
          <Box>
            <Input
              focusBorderColor="eira.2"
              size={"sm"}
              _placeholder={{ color: "eira.2.4" }}
              color={"eira.2"}
              value={searchText}
              onChange={handleSearchTextChange}
            />

            <Box>
              {searchText && (
                <>
                  {filteredOptions.map((option, index) => (
                    <Text
                      textAlign={"center"}
                      maxW={"50%"}
                      bg={"eira.2.1"}
                      py={1}
                      borderRadius={"2xl"}
                      color={"eira.2"}
                      fontWeight={"bold"}
                      my={1}
                      key={`option-${index}`}
                      _hover={{ cursor: "pointer" }}
                      onClick={() => handleCheckboxChange(option)}
                    >
                      {option}
                    </Text>
                  ))}
                </>
              )}
            </Box>
          </Box>
          <Box>
            {motivoConsulta.map((option, index) => (
              <Button
                key={`selected-option-${index}`}
                size={"xs"}
                bg={"eira.2"}
                color={"white"}
                variant="outline"
                textAlign={"center"}
                _hover={{
                  bg: "red.500",
                  color: "white",
                  _before: {
                    display: "inline-block",
                    marginRight: "5px",
                    fontSize: "16px",
                    lineHeight: "1",
                    textAlign: "center",
                    verticalAlign: "middle",
                    fontWeight: "bold",
                    width: "16px",
                    height: "16px",
                    background: "white",
                    borderRadius: "50%",
                    color: "red.500",
                    boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.75)",
                  },
                }}
                onClick={() => removeAntecedente(option)}
              >
                {option}
                <FaTimes
                  style={{ marginLeft: "5px", display: "inline-block" }}
                />
              </Button>
            ))}
          </Box>
        </FormControl>

        <FormControl mb={4}>
          <FormLabel color={"eira.1"}>Enfermedad Actual</FormLabel>
          <Textarea
            onChange={(e) => onChange(e.target.value, "enfermedadActual")}
            rows={3}
            resize="vertical"
            required
          />
        </FormControl>

        <FormControl mb={4}>
          <FormLabel color={"eira.1"}>Diagnóstico de Consulta</FormLabel>
          <Textarea
            onChange={(e) => onChange(e.target.value, "diagnosticoConsulta")}
            rows={3}
            resize="vertical"
            required
          />
        </FormControl>
      </form>
    </Box>
  );
};

export default FormularioConsulta;
