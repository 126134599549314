import { AddIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  IconButton,
  Box,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import CreateAdmin from "../admin/CreateAdmin";
import CreateDoctor from "../doctor/CreateDoctor";
import CreateSpeciality from "../speciality/CreateSpeciality";
import CreatePatient from "../patient/CreatePatient";
import CreateMedicine from "../medicines/CreateMedicine";
import { useSelector } from "react-redux";

const CreateUser = () => {
  const user = useSelector((state) => state.user);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const path = useLocation().pathname.slice(1);

  return (
    <Box>
      <IconButton
        icon={<AddIcon />}
        color={"white"}
        bg="eira.1"
        _hover={{ bg: "eira.1.5" }}
        onClick={onOpen}
        ml={2}
        size="sm"
      />

      <Modal isOpen={isOpen} onClose={onClose} size="5xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            borderBottomWidth="1px"
            color={"eira.1"}
            textAlign={"center"}
          >
            AGREGAR {path.toUpperCase()}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {path === "admin" && <CreateAdmin path={path} onClose={onClose} />}
            {path === "medicos" && (
              <CreateDoctor path={path} onClose={onClose} />
            )}
            {user.role === "doctor" && <CreatePatient path onClose={onClose} />}
            {user.role === "admin" && path === "pacientes" && (
              <CreatePatient path onClose={onClose} />
            )}

            {path === "medicinas" && <CreateMedicine path onClose={onClose} />}
            {path === "especialidades" && (
              <CreateSpeciality path onClose={onClose} />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CreateUser;
