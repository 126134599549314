import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../../components/Login";
import ForgotPassword from "../../commons/ForgotPassword";
import CreatePassword from "../../components/user/CreatePassword";
import ConsultaDetail from "../../components/doctor/GestConsultas/ConsultaDetail";
import Recipe from "../../components/doctor/GestConsultas/Recipe";

export const LoginRoutes = () => (
  <Routes>
    <Route path="/" element={<Login />} />
    <Route path="/detalle-consulta/:consultaId" element={<ConsultaDetail />} />
    <Route path="/recipe/:consultaId" element={<Recipe />} />
    <Route path="/reset-password" element={<ForgotPassword />} />
    <Route path="/create-pass/:token" element={<CreatePassword />} />
    <Route path="/passreset/:resetToken" element={<CreatePassword />} />
  </Routes>
);
