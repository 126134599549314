import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePatient from "../components/patient/HomePatient";
import ConsultaDetail from "../components/doctor/GestConsultas/ConsultaDetail";
import Recipe from "../components/doctor/GestConsultas/Recipe";

export const PatientRoutes = () => {
  return (
    <Routes>
      <Route
        path="/detalle-consulta/:consultaId"
        element={<ConsultaDetail />}
      />
      <Route path="/recipe/:consultaId" element={<Recipe />} />
      <Route path="/" element={<HomePatient />} />
    </Routes>
  );
};
