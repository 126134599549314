import { Button, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

const IconButtonNavbar = ({ link, icon, text, path }) => {
  return (
    <Link to={`${link}`}>
      <Button
        background={"none"}
        size={"xs"}
        color="eira.2"
        my={6}
        _hover={{
          textDecor: "none",
          transform: "scale(1.2)",
          transition: "transform 0.3s ease-in-out",
        }}
      >
        <Flex
          direction="column"
          alignItems="center"
          p={1}
          bg={path === `${text.toLowerCase()}` ? "eira.2.1" : "none"}
          borderRadius={path === `${text.toLowerCase()}` ? "xl" : "none"}
        >
          {icon}
          <Text fontSize={10} textAlign="center" color="eira.2" mt={2}>
            {text}
          </Text>
        </Flex>
      </Button>
    </Link>
  );
};

export default IconButtonNavbar;
