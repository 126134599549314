import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import Variables from "./Variables";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import InformacionConsulta from "./InformacionConsulta";
import FormularioConsulta from "./FormularioConsulta";
import Medicamentos from "./Medicamentos";
import Examenes from "./Examenes";
import FinalizarConsulta from "./FinalizarConsulta";
import FinalizarConsultaMobile from "./FinalizarConsultaMobile";
import API_BASE_URL from "../../../utils/apiBaseURL";
import { createConsulta } from "../../../store/reducers/consulta.reducer";
import axios from "axios";
import { useMediaQuery } from "@chakra-ui/react";
import Swal from "sweetalert2";
import { getCitasByDoctor } from "../../../store/reducers/citas.reducer";
function ConsultasTabs() {
  const [isDesktop] = useMediaQuery("(min-width: 800px)");
  const [tabIndex, setTabIndex] = useState(0);
  const citas = useSelector((state) => state.citas);
  const navigate = useNavigate();
  const { citaId } = useParams();
  const [cita, setCita] = useState(null);
  const [variables, setVariables] = useState(null);
  const [consultaForm, setConsultaForm] = useState({
    motivoConsulta: [],
    enfermedadActual: "",
    diagnosticoConsulta: "",
  });
  const [examenesForm, setExamenesForm] = useState([]);
  const [medicamentos, setMedicamentos] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    const cita = citas.find((cita) => cita._id === citaId) || {};
    setCita(cita);

    if (cita && cita.paciente && cita.paciente.variables) {
      setVariables(cita.paciente.variables);
    } else {
      setVariables({
        peso: "0",
        pulso: "0",
        respiracion: "0",
        temperatura: "0",
        tensionArterialMN: "0",
        tensionArterialMX: "0",
      });
    }
  }, [citas, citaId]);

  let estadoCita = false;
  if (cita) {
    estadoCita = cita.estado === "Confirmada";
  }
  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  const handleFormVariables = (event, fieldName) => {
    setVariables({ ...variables, [fieldName]: event.target.value });
  };

  const handleFormConsulta = (event, fieldName) => {
    const value = event.target ? event.target.value : event; // Extraer el valor del evento
    setConsultaForm({ ...consultaForm, [fieldName]: value });
  };

  const handleAddMedicamento = (medicamento) => {
    setMedicamentos((prevMedicamentos) => [...prevMedicamentos, medicamento]);
  };

  const handleRemoveMedicamento = (index) => {
    setMedicamentos((prevMedicamentos) => {
      const updatedMedicamentos = [...prevMedicamentos];
      updatedMedicamentos.splice(index, 1);
      return updatedMedicamentos;
    });
  };

  const consulta = {
    variables,
    consulta: consultaForm,
    medicamentos,
    examenes: examenesForm,
    cita,
  };

  const handleConfirmVariables = async () => {
    if (cita && cita.paciente) {
      const idPaciente = cita.paciente._id;
      await axios.put(
        `${API_BASE_URL}/api/doctor/pacientes/${idPaciente}`,
        variables
      );
    }
  };

  const handleSubmit = async () => {
    const resp = await dispatch(createConsulta(consulta));
    if (resp) {
      await Swal.fire({
        title: "Finalizada",
        text: "La consulta ha sido creada correctamente",
        icon: "success",
      });
      dispatch(getCitasByDoctor(cita.doctor._id));
      navigate("/");
    }
  };
  const tabTittle = [
    "Información",
    "Variables",
    "Consulta",
    "Indicaciones",
    "Examenes",
    "Finalizar Consulta",
  ];

  const incrementar = () => {
    setTabIndex((prevCount) => prevCount + 1);
  };

  const disminuir = () => {
    if (tabIndex > 0) {
      setTabIndex((prevCount) => prevCount - 1);
    }
  };

  return (
    <>
      {!cita || Object.keys(cita).length === 0 ? (
        <Text>Cargando</Text>
      ) : (
        <Box
          bg={"white"}
          boxShadow="md"
          borderRadius={"xl"}
          mt={isDesktop && 5}
          py={isDesktop && 10}
          px={isDesktop && 5}
          mx={isDesktop && 5}
        >
          <Tabs index={tabIndex} onChange={handleTabsChange}>
            {isDesktop ? (
              <TabList>
                <Tab color={"eira.2"}>Información</Tab>
                <Tab isDisabled={!estadoCita} color={"eira.2"}>
                  Variables
                </Tab>
                <Tab isDisabled={!estadoCita} color={"eira.2"}>
                  Consulta
                </Tab>
                <Tab isDisabled={!estadoCita} color={"eira.2"}>
                  Indicaciones
                </Tab>
                <Tab isDisabled={!estadoCita} color={"eira.2"}>
                  Examenes
                </Tab>
                <Tab isDisabled={!estadoCita} color={"eira.2"}>
                  Finalizar Consulta
                </Tab>
              </TabList>
            ) : (
              <Box>
                {estadoCita && (
                  <Flex justifyContent="space-between" pt={10}>
                    <Button
                      variant={"outline"}
                      size={"xs"}
                      color="white"
                      bg={"eira.2"}
                      visibility={tabIndex > 0 ? "visible" : "hidden"}
                      onClick={disminuir}
                    >
                      {tabTittle[tabIndex - 1]}
                    </Button>

                    <Button
                      variant={"outline"}
                      size={"xs"}
                      color="white"
                      bg={"eira.2"}
                      visibility={!(tabIndex === 5) ? "visible" : "hidden"}
                      onClick={incrementar}
                    >
                      {tabTittle[tabIndex + 1]}
                    </Button>
                  </Flex>
                )}
              </Box>
            )}

            {!cita ? (
              <h1>Cargando</h1>
            ) : (
              <TabPanels>
                <TabPanel>
                  <InformacionConsulta cita={cita} />
                </TabPanel>
                <TabPanel>
                  <Variables
                    values={variables}
                    onChange={handleFormVariables}
                    confirmVariables={handleConfirmVariables}
                  />
                </TabPanel>
                <TabPanel>
                  <FormularioConsulta
                    values={consultaForm}
                    onChange={handleFormConsulta}
                  />
                </TabPanel>
                <TabPanel>
                  <Medicamentos
                    medicamentos={medicamentos}
                    onAddMedicamento={handleAddMedicamento}
                    onRemoveMedicamento={handleRemoveMedicamento}
                  />
                </TabPanel>
                <TabPanel>
                  <Examenes
                    checkedValues={examenesForm}
                    setCheckedValues={setExamenesForm}
                  />
                </TabPanel>
                <TabPanel>
                  {isDesktop ? (
                    <FinalizarConsulta
                      consulta={consulta}
                      onSubmit={handleSubmit}
                      cita={cita}
                    />
                  ) : (
                    <FinalizarConsultaMobile
                      consulta={consulta}
                      onSubmit={handleSubmit}
                      cita={cita}
                    />
                  )}
                </TabPanel>
              </TabPanels>
            )}
          </Tabs>
        </Box>
      )}
    </>
  );
}

export default ConsultasTabs;
