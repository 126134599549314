import React, { useState } from "react";
import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
} from "@chakra-ui/react";
import { format } from "date-fns";
import Pagination from "../../commons/PaginationCitas";
import { useDispatch, useSelector } from "react-redux";
import { deleteConsulta } from "../../store/reducers/consulta.reducer";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

const TableConsultas = () => {
  const [isDesktop] = useMediaQuery("(min-width: 800px)");
  const dispatch = useDispatch();
  const ItemsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const consultas = useSelector((state) => state.consultas);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const deleteItem = async (item) => {
    await dispatch(deleteConsulta(item._id));
  };

  const confirmDelete = async (item) => {
    const confirm = await Swal.fire({
      title: `Deseas eliminar la consulta?`,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      allowOutsideClick: false,
      cancelButtonColor: "red",
    });

    if (confirm.isConfirmed) {
      deleteItem(item);
    }
  };

  const formatDateAndTime = (dateTime) => {
    return format(new Date(dateTime), "dd/MM/yy HH:mm");
  };

  return (
    <Box
      bg="white"
      boxShadow="md"
      borderRadius="xl"
      mt={20}
      px={isDesktop ? 5 : 0}
      mx={isDesktop ? 10 : 0}
    >
      <Table>
        <Thead>
          <Tr textAlign="center">
            <Th color="eira.2" textAlign="center">
              Paciente
            </Th>
            {isDesktop && (
              <>
                <Th color="eira.2" textAlign="center">
                  Motivo de Consulta
                </Th>
              </>
            )}
            <Th color="eira.2" textAlign="center">
              Fecha
            </Th>
            <Th color="eira.2" textAlign="center">
              Acción
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {consultas
            .slice((currentPage - 1) * ItemsPerPage, currentPage * ItemsPerPage)
            .map((item, index) => (
              <Tr key={index}>
                <Th textAlign="center">
                  <Link to={`/paciente/${item.paciente._id}`}>
                    {item.paciente.name}
                  </Link>
                </Th>
                {isDesktop && (
                  <>
                    <Th>
                      <Center>
                        <Flex>
                          {item.consulta.motivoConsulta.map((mot, index) => (
                            <Badge mx={2} key={index}>
                              {mot}
                            </Badge>
                          ))}
                        </Flex>
                      </Center>
                    </Th>
                  </>
                )}
                <Th>
                  <Text>{formatDateAndTime(item.date)}</Text>
                </Th>
                <Th>
                  <Link to={`/detalle-consulta/${item._id}`}>
                    <Button
                      size="xs"
                      bg="eira.2"
                      mx={1}
                      _hover={{ bg: "eira.2.2" }}
                      color="white"
                    >
                      Detalles
                    </Button>
                  </Link>
                  <Button
                    size="xs"
                    bg="red"
                    mx={1}
                    _hover={{ bg: "red.100" }}
                    onClick={() => confirmDelete(item)}
                    color="white"
                  >
                    Eliminar
                  </Button>
                </Th>
              </Tr>
            ))}
        </Tbody>
      </Table>
      <Pagination
        data={consultas}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        ItemsPerPage={ItemsPerPage}
      />
    </Box>
  );
};

export default TableConsultas;
