import React from "react";
import { Route, Routes } from "react-router-dom";
import HomeDoctor from "../components/doctor/HomeDoctor";
import TablePatientsByDoctor from "../components/doctor/GestPatient/TablePatientsByDoctor";
import InfoPatient from "../components/doctor/GestPatient/InfoPatient";
import ConsultasTabs from "../components/doctor/GestConsultas/ConsultasTabs";
import CreateConsulta from "../components/doctor/GestConsultas/CreateConsulta";
import TableConsultas from "../components/doctor/TableConsultas";
import ConsultaDetail from "../components/doctor/GestConsultas/ConsultaDetail";
import Recipe from "../components/doctor/GestConsultas/Recipe";
export const DoctorRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeDoctor />} />
      <Route
        path="/detalle-consulta/:consultaId"
        element={<ConsultaDetail />}
      />
      <Route path="/recipe/:consultaId" element={<Recipe />} />
      <Route path="/pacientes" element={<TablePatientsByDoctor />} />
      <Route path="/paciente/:patientId" element={<InfoPatient />} />
      <Route path="/consulta/:pacienteId" element={<ConsultasTabs />} />
      <Route path="/consulta/:pacienteId/:citaId" element={<ConsultasTabs />} />
      <Route path="/consultas/" element={<TableConsultas />} />
      <Route path="/create-consulta/" element={<CreateConsulta />} />
    </Routes>
  );
};
