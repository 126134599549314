import React from "react";
import {
  Select,
  FormControl,
  Switch,
  Button,
  Grid,
  Flex,
  Box,
  Text,
  Center,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { createAdmin } from "../../store/reducers/admin.reducer";
import { useForm } from "react-hook-form";
import CreateUserForm from "../user/CreateUserForm";

const CreateAdmin = ({ path, onClose }) => {
  const { handleSubmit, register } = useForm();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("position", data.position);
    formData.append("superAdmin", data.superAdmin);
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("identificacion", data.identificacion);
    formData.append("dateOfBirth", data.dateOfBirth);
    formData.append("gender", data.gender);
    formData.append("phone", data.phone);
    formData.append("state", data.state);
    formData.append("city", data.city);
    formData.append("address", data.address);
    formData.append("role", "admin");
    const resp = await dispatch(createAdmin(formData));
    if (resp) {
      onClose();
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex align="center" justify="center">
          <Box w="100%">
            <Grid
              templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
              gap={6}
              mx="auto"
              py={4}
              px={2}
            >
              <CreateUserForm register={register} />
              <Box>
                <FormControl isRequired>
                  <Text as={"b"} color={"eira.2.7"}>
                    Cargo
                  </Text>
                  <Select
                    placeholder="Select option"
                    {...register("position")}
                    focusBorderColor="eira.2"
                    _placeholder={{ color: "eira.2.4" }}
                    color={"eira.2.8"}
                  >
                    <option value="CEO">CEO</option>
                    <option value="Administrador">Administrador</option>
                    <option value="Programador">Programador</option>
                  </Select>
                </FormControl>
              </Box>
              <Box>
                <FormControl isRequired>
                  <FormControl
                    display="flex"
                    alignItems="center"
                    mt={3}
                    textAlign={"center"}
                  >
                    <Text
                      as={"b"}
                      color={"eira.2.7"}
                      textAlign={"center"}
                      mr={6}
                    >
                      Super Admin?
                    </Text>
                    <Switch id="email-alerts" {...register("superAdmin")} />
                  </FormControl>
                </FormControl>
              </Box>
            </Grid>
          </Box>
        </Flex>
        <Center my={5}>
          <Button
            type="submit"
            bg={"eira.1"}
            color={"white"}
            className="animateFloat"
            _hover={{
              bg: "eira.1",
            }}
          >
            Crear nuevo {path}
          </Button>
        </Center>
      </form>
    </>
  );
};

export default CreateAdmin;
