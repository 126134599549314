import { useForm } from "react-hook-form";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Button,
  useDisclosure,
  Stack,
} from "@chakra-ui/react";
import { AiOutlineEdit } from "react-icons/ai";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { handleAddUser } from "../commons/ToastFire";
import { updateAdmin } from "../store/reducers/admin.reducer";
import { updateDoctor } from "../store/reducers/doctor.reducer";
import { updatePatient } from "../store/reducers/patient.reducer";
import { updateSpeciality } from "../store/reducers/speciality.reducer";
import { updateMedicine } from "../store/reducers/medicine.reducer";
import { useLocation } from "react-router-dom";
import EditAdmin from "../components/admin/EditAdmin";
import EditSpeciality from "../components/speciality/EditSpeciality";
import EditDoctor from "../components/doctor/EditDoctor";
import EditPatient from "../components/patient/EditPatient";
import EditMedicine from "../components/medicines/EditMedicine";

const EditUser = ({ user }) => {
  const path = useLocation().pathname.slice(1);
  const { register, reset, handleSubmit } = useForm();
  const data = [user];
  const [employeeData, setEmployeeData] = useState(data);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();

  const onChange = (e, _id) => {
    const { name, value } = e.target;
    const editData = employeeData.map((item) =>
      item._id === _id && name ? { ...item, [name]: value } : item
    );
    setEmployeeData(editData);
  };

  const role = {
    admin: updateAdmin,
    medicos: updateDoctor,
    pacientes: updatePatient,
    especialidades: updateSpeciality,
    medicinas: updateMedicine,
  };

  const onSubmit = async (data) => {
    const userId = user._id;
    const resp = await dispatch(role[path]({ data, userId }));
    handleAddUser(resp, data, reset, onClose);
  };

  return (
    <>
      <Button
        onClick={onOpen}
        size="sm"
        bg={"eira.1.7"}
        _hover={{
          bg: "eira.1.2",
        }}
      >
        <AiOutlineEdit fontSize={"20"} color={"#edf2f7"} />
      </Button>

      <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader
            borderBottomWidth="1px"
            color={"eira.1"}
            textAlign={"center"}
          >
            Editar {user.name}
          </DrawerHeader>
          <DrawerBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <>
                {employeeData.map(
                  ({
                    _id,
                    name,
                    address,
                    phone,
                    position,
                    superAdmin,
                    brandName,
                    genericName,
                    healthRegister,
                    identificacion,
                    identificacionMedica,
                    representative,
                  }) => (
                    <Stack spacing={2} key={_id}>
                      {path === "admin" && (
                        <EditAdmin
                          onChange={onChange}
                          register={register}
                          _id={_id}
                          name={name}
                          address={address}
                          phone={phone}
                          identificacion={identificacion}
                          superAdmin={superAdmin}
                          position={position}
                        />
                      )}
                      {path === "medicos" && (
                        <EditDoctor
                          onChange={onChange}
                          register={register}
                          _id={_id}
                          name={name}
                          address={address}
                          phone={phone}
                          identificacion={identificacion}
                          identificacionMedica={identificacionMedica}
                        />
                      )}
                      {path === "pacientes" && (
                        <EditPatient
                          onChange={onChange}
                          register={register}
                          _id={_id}
                          name={name}
                          address={address}
                          phone={phone}
                          identificacion={identificacion}
                        />
                      )}
                      {path === "especialidades" && (
                        <EditSpeciality
                          register={register}
                          name={name}
                          onChange={onChange}
                          _id={_id}
                        />
                      )}
                      {path === "medicinas" && (
                        <EditMedicine
                          onChange={onChange}
                          _id={_id}
                          register={register}
                          brandName={brandName}
                          genericName={genericName}
                          healthRegister={healthRegister}
                          representative={representative}
                        />
                      )}

                      <Stack spacing={10}>
                        <Stack
                          direction={{ base: "column", sm: "row" }}
                          align={"start"}
                          justify={"space-between"}
                        ></Stack>
                        <Button
                          type="submit"
                          bg={"eira.1"}
                          color={"white"}
                          _hover={{
                            bg: "eira.1.5",
                          }}
                        >
                          Editar
                        </Button>
                      </Stack>
                    </Stack>
                  )
                )}
              </>
            </form>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
export default EditUser;
