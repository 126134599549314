import React, { useState } from "react";
import {
  FormControl,
  Grid,
  Box,
  Input,
  CheckboxGroup,
  Checkbox,
  Button,
  Text,
  Center,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { createDoctor } from "../../store/reducers/doctor.reducer";
import { useForm } from "react-hook-form";
import CreateUserForm from "../user/CreateUserForm";
import { FaTimes } from "react-icons/fa";
import FormHorariosDisp from "./FormHorariosDisp";

const CreateDoctor = ({ path, onClose }) => {
  const specialities = useSelector((state) => state.specialities);
  const [searchText, setSearchText] = useState("");
  const [checkedValues, setCheckedValues] = useState([]);
  const [selectedHours, setSelectedHours] = useState([8, 16]); // Horas seleccionadas por defecto
  const [selectedDays, setSelectedDays] = useState([1, 7]);
  const { handleSubmit, reset, register } = useForm();
  const dayLabels = ["Lun", "Mar", "Mié", "Jue", "Vie", "Sáb", "Dom"];

  const handleHourChange = (values) => {
    setSelectedHours(values);
  };
  const handleDayChange = (values) => {
    setSelectedDays(values);
  };
  const handleCheckboxChange = (value) => {
    if (checkedValues.includes(value)) {
      setCheckedValues((prevCheckedValues) =>
        prevCheckedValues.filter((val) => val !== value)
      );
    } else {
      setCheckedValues((prevCheckedValues) => [...prevCheckedValues, value]);
    }
    setSearchText(""); // Aquí limpias el campo de búsqueda
  };
  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };

  const filteredOptions = specialities.filter((option) =>
    option.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("identificacion", data.identificacion);
    formData.append("dateOfBirth", data.dateOfBirth);
    formData.append("gender", data.gender);
    formData.append("phone", data.phone);
    formData.append("state", data.state);
    formData.append("city", data.city);
    formData.append("address", data.address);
    formData.append("role", "doctor");
    formData.append("especialidades", checkedValues);
    formData.append("identificacionMedica", data.identificacionMedica);
    formData.append(
      "dataHorario",
      JSON.stringify({
        dias: selectedDays,
        horas: selectedHours,
        duracion: data.duracionConsulta,
      })
    );
    const resp = await dispatch(createDoctor(formData));

    if (resp) {
      reset();
      onClose();
    }
  };

  function removeSpeciality(name) {
    const nuevasEspecialidades = checkedValues.filter(
      (especialidad) => especialidad !== name
    );
    setCheckedValues(nuevasEspecialidades);
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={4}>
          <CreateUserForm register={register} />

          <Box rounded="lg">
            <Text as={"b"} color={"eira.2.7"}>
              Identificación Médica
            </Text>
            <FormControl isRequired>
              <Input
                {...register("identificacionMedica")}
                type="number"
                focusBorderColor="eira.2"
                _placeholder={{ color: "eira.2.4" }}
                color={"eira.2"}
              />
            </FormControl>
            <FormControl py={2}>
              <Text as={"b"} color={"eira.2.7"}>
                Especialidad Médica
              </Text>
              <Box>
                <Input
                  focusBorderColor="eira.2"
                  size={"sm"}
                  _placeholder={{ color: "eira.2.4" }}
                  color={"eira.2"}
                  value={searchText}
                  onChange={handleSearchTextChange}
                />
                {searchText && (
                  <CheckboxGroup value={checkedValues}>
                    {filteredOptions.slice(0, 5).map((option) => (
                      <Checkbox
                        color={"eira.2"}
                        mx={2}
                        key={option._id}
                        value={option._id}
                        onChange={() => handleCheckboxChange(option.name)}
                      >
                        {option.name}
                      </Checkbox>
                    ))}
                  </CheckboxGroup>
                )}

                {checkedValues.map((option) => (
                  <Button
                    key={option}
                    size={"xs"}
                    bg={"eira.2"}
                    color={"white"}
                    variant="outline"
                    textAlign={"center"}
                    _hover={{
                      bg: "red.500",
                      color: "white",
                      _before: {
                        display: "inline-block",
                        marginRight: "5px",
                        fontSize: "16px",
                        lineHeight: "1",
                        textAlign: "center",
                        verticalAlign: "middle",
                        fontWeight: "bold",
                        width: "16px",
                        height: "16px",
                        background: "white",
                        borderRadius: "50%",
                        color: "red.500",
                        boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.75)",
                      },
                    }}
                    onClick={() => removeSpeciality(option)}
                  >
                    {option}
                    <FaTimes
                      style={{ marginLeft: "5px", display: "inline-block" }}
                    />
                  </Button>
                ))}
              </Box>
            </FormControl>
          </Box>
          <FormHorariosDisp
            selectedHours={selectedHours}
            selectedDays={selectedDays}
            register={register}
            handleHourChange={handleHourChange}
            handleDayChange={handleDayChange}
            dayLabels={dayLabels}
          />
        </Grid>
        <Center my={5}>
          <Button
            type="submit"
            bg={"eira.1"}
            color={"white"}
            _hover={{
              bg: "eira.1.5",
            }}
          >
            Crear nuevo {path}
          </Button>
        </Center>
      </form>
    </>
  );
};

export default CreateDoctor;
