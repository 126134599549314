import { Flex, Stat, StatLabel, StatNumber, Text } from "@chakra-ui/react";
import Card from "./Card";
import React from "react";
import { Link } from "react-router-dom";

export default function Default(props) {
  const { startContent, name, titleDescription, description, value, path } =
    props;
  const textColor = "eira.1";

  return (
    <Link to={path}>
      <Card py="25px" bg="white" borderRadius="xl" boxShadow="md" pl={4}>
        <Flex
          my="auto"
          h="100%"
          align={{ base: "center", xl: "start" }}
          justify={{ base: "center", xl: "center" }}
        >
          {startContent}

          <Stat my="auto" ms={startContent ? "18px" : "0px"}>
            <StatLabel
              lineHeight="100%"
              color={"eira.2"}
              fontSize={{
                base: "sm",
              }}
            >
              {name}
            </StatLabel>
            <StatNumber
              color={textColor}
              fontSize={{
                base: "2xl",
              }}
            >
              {value}
            </StatNumber>
            {titleDescription ? (
              <Flex align="center">
                <Text color="green.500" fontSize="xs" fontWeight="700" me="5px">
                  {titleDescription}: {description}
                </Text>
              </Flex>
            ) : null}
          </Stat>
        </Flex>
      </Card>
    </Link>
  );
}
