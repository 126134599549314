import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  useColorModeValue,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  createPassRequest,
  resetPassRequest,
} from "../../store/reducers/user.reducer";
import { handleAddUser } from "../../commons/ToastFire";
import Toast from "../../commons/Toast";

export default function CreatePassword() {
  const { resetToken, token } = useParams();
  const dispatch = useDispatch();
  const { register, handleSubmit, reset } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (e) => {
    const { passwordValidate, password } = e;
    if (passwordValidate === password) {
      if (resetToken) {
        const resp = await dispatch(
          resetPassRequest({ newPassword: password, resetLink: resetToken })
        );
        handleAddUser(resp, null, reset, null);
        navigate("/");
      } else {
        const resp = await dispatch(createPassRequest({ token, password }));
        if (resp) {
          localStorage.clear();
          Toast.fire({
            icon: "success",
            title: `Tu contraseña fue creada con exito`,
          });
          navigate("/");
        }
      }
    } else {
      Toast.fire({
        icon: "error",
        title: `Contraseñas no coinciden`,
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex align={"center"} justify={"center"}>
          <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
            <Box
              rounded={"lg"}
              bg={useColorModeValue("white", "gray.700")}
              boxShadow={"lg"}
              p={8}
            >
              <Stack spacing={4}>
                <FormControl id="email" isRequired>
                  <FormLabel>Contraseña</FormLabel>
                  <Input type="password" {...register("password")} />
                </FormControl>
                <FormControl id="password" isRequired>
                  <FormLabel>Repita su Contraseña</FormLabel>
                  <Input type="password" {...register("passwordValidate")} />
                </FormControl>
                <Stack spacing={10}>
                  <Stack
                    direction={{ base: "column", sm: "row" }}
                    align={"start"}
                    justify={"space-between"}
                  ></Stack>
                  <Button
                    type="submit"
                    bg={"rgba(0,167,170)"}
                    color={"black"}
                    _hover={{
                      bg: "rgba(0,167,170,0.5)",
                    }}
                  >
                    Crear
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        </Flex>
      </form>
    </>
  );
}
