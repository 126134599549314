import { Box, Button } from "@chakra-ui/react";
import React from "react";

const Pagination = ({
  data,
  searchValue,
  handlePageChange,
  currentPage,
  ItemsPerPage,
}) => {
  const totalPages = Math.ceil(
    data.filter((item) =>
      Object.values(item)
        .filter((field) => typeof field === "string")
        .join(" ")
        .toLowerCase()
        .includes(searchValue.toLowerCase())
    ).length / ItemsPerPage
  );

  const maxButtons = 6; // número máximo de botones que se mostrarán en la paginación
  let startPage = 1;
  let endPage = totalPages;
  if (totalPages > maxButtons) {
    const middleButton = Math.floor(maxButtons / 2); // botón central
    if (currentPage > middleButton) {
      startPage = currentPage - middleButton;
      endPage = currentPage + middleButton - 1;
    } else {
      endPage = maxButtons;
    }
    if (endPage > totalPages) {
      startPage = totalPages - maxButtons + 1;
      endPage = totalPages;
    }
  }

  return (
    <Box
      position={"fixed"}
      textAlign="center"
      width={"100%"}
      p={5}
      left={0}
      bottom={0}
    >
      {Array.from(Array(endPage - startPage + 1)).map((_, i) => (
        <Button
          backgroundColor={
            i + startPage === currentPage ? "eira.1.5" : "eira.1"
          }
          borderColor="eira.2"
          borderWidth={1}
          color={i + startPage === currentPage ? "eira.2" : "white"}
          key={i + startPage}
          mx={1}
          onClick={() => handlePageChange(i + startPage)}
        >
          {i + startPage}
        </Button>
      ))}
    </Box>
  );
};

export default Pagination;
