import React from "react";
import { Button, Th, Thead, Tr, Center, useMediaQuery } from "@chakra-ui/react";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import UserDetail from "../../commons/UserDetail";
import DeleteUser from "../../commons/DeleteUser";
import EditUser from "../../commons/EditUser";

const TableDoctor = ({ data, user, suspendOrActiveUser }) => {
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");

  return (
    <>
      <Thead>
        <Tr>
          <Th color={"eira.3"}>Nombre</Th>
          {isLargerThan800 && (
            <>
              <Th color={"eira.3"}>Email</Th>
              <Th color={"eira.3"}>Telefono</Th>
              <Th color={"eira.3"}>Estado</Th>
              <Th color={"eira.3"}>Acción</Th>
            </>
          )}
        </Tr>
      </Thead>
      <tbody>
        {data.map((item, index) => (
          <Tr key={index}>
            <Th color={"eira.2"}>
              {item.name.split(" ").slice(0, 1).join(" ")}
            </Th>
            {isLargerThan800 && (
              <>
                <Th color={"eira.2"}>{item.email}</Th>
                <Th color={"eira.2"}>{item.phone}</Th>
              </>
            )}
            <Th>
              <Center>
                <Button
                  size={"xs"}
                  bg={
                    item.activationCode === 0 && !item.isActivated
                      ? "red"
                      : "green"
                  }
                  _hover={{
                    bg: "gray.600",
                  }}
                  onClick={() => {
                    user.superAdmin &&
                      suspendOrActiveUser(
                        item.email,
                        item.name,
                        item.isActivated
                      );
                  }}
                >
                  {item.activationCode === 0 && !item.isActivated ? (
                    <AiOutlineClose fontSize={"20px"} color="white" />
                  ) : (
                    <AiOutlineCheck fontSize={"20px"} color="white" />
                  )}
                </Button>
              </Center>
            </Th>
            <Th>
              <div style={{ display: "flex" }}>
                <UserDetail user={item} />
                {user.superAdmin && (
                  <>
                    <DeleteUser user={item} typeOfItem={"doctor"} />
                    <EditUser user={item} />
                  </>
                )}
              </div>
            </Th>
          </Tr>
        ))}
      </tbody>
    </>
  );
};

export default TableDoctor;
