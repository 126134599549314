import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Button,
  useDisclosure,
  FormControl,
  Text,
  Select,
  Stack,
  Box,
  Alert,
  AlertIcon,
  AlertDescription,
  useMediaQuery,
  Center,
} from "@chakra-ui/react";
import { FaRegCalendarPlus } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { createCita } from "../../../store/reducers/citas.reducer";
import { handleAddUser } from "../../../commons/ToastFire";
import { useState } from "react";
import Calendar from "react-calendar";

const CreateCitas = ({ paciente }) => {
  const [isDesktop] = useMediaQuery("(min-width: 800px)");
  const citas = useSelector((state) => state.citas);
  const dispatch = useDispatch();
  const { handleSubmit, reset, register } = useForm();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectedDate, setSelectedDate] = useState(null);
  const user = useSelector((state) => state.user);

  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  const onSubmit = async (data) => {
    const resp = await dispatch(
      createCita({ ...data, doctor: user._id, fecha: selectedDate, paciente })
    );
    handleAddUser(resp, data, reset, onClose);
  };

  let horasDisponiblesSinCitas = user.horarioDisponible.horas; // Valor inicial antes del bloque if

  if (selectedDate) {
    const citasFiltradas = citas.filter((cita) => {
      const fechaCita = new Date(cita.fecha);
      return fechaCita.toDateString() === selectedDate.toDateString();
    });

    const horasCitas = citasFiltradas.map((cita) => cita.hora);
    horasDisponiblesSinCitas = user.horarioDisponible.horas.filter(
      (hora) => !horasCitas.includes(hora)
    );
  }

  function filterDate(date) {
    const day = date.getDay(); // Obtén el día de la semana (0: domingo, 1: lunes, ...)
    const weekDays = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ];
    const selectedDay = weekDays[day]; // Obtén el nombre del día de la semana
    return user.horarioDisponible.dias.includes(selectedDay); // Devuelve true si el día está en la lista de días disponibles
  }

  return (
    <>
      <Center>
        <Button
          color={"white"}
          bg="eira.2"
          textAlign={"center"}
          _hover={{ bg: "eira.2.5" }}
          onClick={onOpen}
          size={isDesktop ? "sm" : "xs"}
          mt={2}
        >
          <FaRegCalendarPlus fontSize={"20px"} />
        </Button>
      </Center>

      <Drawer placement="right" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />

        <DrawerContent>
          <DrawerHeader
            borderBottomWidth="1px"
            color={"eira.1"}
            textAlign={"center"}
          >
            AGREGAR
          </DrawerHeader>
          <DrawerBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl isRequired py={2}>
                <Text as={"b"} color={"eira.2.7"} mb={2}>
                  Fecha de la cita
                </Text>
                <Box px={5} py={5}>
                  <Calendar
                    onChange={handleDateSelect}
                    value={selectedDate}
                    className={"calendar"}
                    tileDisabled={({ date }) => !filterDate(date)}
                  />
                </Box>
              </FormControl>

              {horasDisponiblesSinCitas.length === 0 ? (
                <Alert status="error">
                  <AlertIcon />
                  <AlertDescription>No hay disponibilidad</AlertDescription>
                </Alert>
              ) : (
                <FormControl isRequired>
                  <Text as={"b"} color={"eira.2.7"}>
                    Horario
                  </Text>
                  <Select
                    {...register("hora")}
                    focusBorderColor="eira.2"
                    color="eira.2"
                    defaultValue=""
                    isDisabled={horasDisponiblesSinCitas.length === 0}
                  >
                    <option value="" disabled>
                      Seleccione un horario
                    </option>
                    {horasDisponiblesSinCitas.length === 0 ? (
                      <option disabled>No hay citas disponibles</option>
                    ) : (
                      horasDisponiblesSinCitas.map((hora) => (
                        <option key={hora} value={hora}>
                          {hora}
                        </option>
                      ))
                    )}
                  </Select>
                </FormControl>
              )}

              <Stack spacing={2}>
                <Stack spacing={10}>
                  <Stack
                    direction={{ base: "column", sm: "row" }}
                    align={"start"}
                    justify={"space-between"}
                  ></Stack>
                  <Button
                    type="submit"
                    bg={"eira.1"}
                    color={"white"}
                    className="animateFloat"
                    _hover={{
                      bg: "eira.1",
                    }}
                  >
                    Agendar Cita
                  </Button>
                  <Button size={"xs"} onClick={onClose} colorScheme="red">
                    Cancelar
                  </Button>
                </Stack>
              </Stack>
            </form>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default CreateCitas;
