import React, { useRef } from "react";
import { Box, Button, Center, Text } from "@chakra-ui/react";
import SignatureCanvas from "react-signature-canvas";
import axios from "axios";
import API_BASE_URL from "../utils/apiBaseURL";
import { useDispatch } from "react-redux";
import { setUser } from "../store/reducers/user.reducer";

const Signature = ({ doctor }) => {
  const dispatch = useDispatch();
  const signatureRef = useRef();

  const clearSignature = () => {
    signatureRef.current.clear();
  };

  const saveSignature = () => {
    const signatureDataURL = signatureRef.current.toDataURL();
    axios
      .put(`${API_BASE_URL}/api/doctor/firma`, { doctor, signatureDataURL })
      .then((resp) => {
        dispatch(setUser(resp.data));
      });
  };

  return (
    <Center bg={"whiteAlpha.800"} flexDirection="column" height="50vh" mt={20}>
      <Text
        color={"eira.2"}
        fontSize={["lg", "xl"]}
        textAlign={"center"}
        fontWeight={"bold"}
        mb={10} // Agrega espacio debajo del texto
      >
        Por Favor registra tu firma
      </Text>

      <Box
        bg={"white"}
        mt={5}
        mb={5}
        width={250}
        height={150}
        border={"1px"}
        borderColor={"eira.2"}
        borderRadius={"md"}
      >
        <SignatureCanvas
          ref={signatureRef}
          penColor="black"
          canvasProps={{
            width: 250,
            height: 150,
            className: "signatureCanvas",
          }}
        />
      </Box>

      <Center>
        <Button
          onClick={saveSignature}
          colorScheme="green"
          size={"sm"}
          mr={[0, 2]}
        >
          Guardar Firma
        </Button>
        <Button
          onClick={clearSignature}
          colorScheme="red"
          size={"sm"}
          ml={[0, 2]}
        >
          Borrar Firma
        </Button>
      </Center>
    </Center>
  );
};

export default Signature;
