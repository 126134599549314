import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import API_BASE_URL from "./utils/apiBaseURL";
import { LoginRoutes } from "./routes/Login/LoginRoutes";
import { AuthenticatedRoutes } from "./routes/Login/AuthenticatedRoutes";
import { getStates } from "./utils/getStatesReducers";
import { setUser } from "./store/reducers/user.reducer.js";
import "./index.css";

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    const userLocalStorg = JSON.parse(localStorage.getItem("user"));
    async function fetchUser() {
      try {
        const response = await axios.post(`${API_BASE_URL}/api/auth/me/`, {
          userLocalStorg,
        });
        if (response) {
          dispatch(setUser(response.data));
          getStates(response.data, dispatch);
        }
      } catch (error) {
        if (error.response.status === 401) {
          dispatch(setUser({ state: "rejected" }));
        }
      }
    }
    fetchUser();
  }, [dispatch]);

  return (
    <>
      <Suspense>
        {user.state === "rejected" ? (
          <LoginRoutes />
        ) : (
          <AuthenticatedRoutes user={user} />
        )}
      </Suspense>
    </>
  );
}

export default App;
