import React from "react";
import MiniStatistics from "../../commons/MiniStatistics";
import { Flex, SimpleGrid } from "@chakra-ui/react";
import { BsCalendarCheck } from "react-icons/bs";
import { FaStethoscope,FaUserMd } from "react-icons/fa";
import { useSelector } from "react-redux";

const PatientStatitics = () => {
  const citas = useSelector((state) => state.citas);
  const doctors = useSelector((state) => state.doctors);
  return (
    <SimpleGrid
      columns={{ base: 1, md: 2, lg: 3, xl: 4, "2xl": 6 }} // Adjusted xl breakpoint
      gap={{ base: "20px", md: "40px" }} // Adjusted gap for different breakpoints
      mb="20px"
    >
      <MiniStatistics
        path="/pacientes"
        startContent={
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"50%"}
            w="56px"
            h="56px"
            bg="eira.2"
          >
            <BsCalendarCheck fontSize={"35px"} color="white" />
          </Flex>
        }
        name="Citas"
        value={citas.length}
      />
      <MiniStatistics
        path="/pacientes"
        startContent={
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"50%"}
            w="56px"
            h="56px"
            bg="eira.2"
          >
            <FaUserMd fontSize={"35px"} color="white" />
          </Flex>
        }
        name="Medicos Disponibles"
        value={doctors.length}
      />
      <MiniStatistics
        path="/pacientes"
        startContent={
          <Flex
            alignItems={"center"}
            justifyContent={"center"}
            borderRadius={"50%"}
            w="56px"
            h="56px"
            bg="eira.2"
          >
            <FaStethoscope fontSize={"35px"} color="white" />
          </Flex>
        }
        name="Consultas Realizadas"
        value="4"
      />
    </SimpleGrid>
  );
};

export default PatientStatitics;
