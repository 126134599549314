import { FormControl, Input, Text } from "@chakra-ui/react";
import React from "react";

const EditSpeciality = ({ register, name, onChange, _id }) => {
  return (
    <FormControl isRequired>
      <Text as={"b"} color={"eira.2.7"}>
        Nombre
      </Text>
      <Input
        {...register("name")}
        value={name}
        focusBorderColor="eira.2"
        color={"eira.2"}
        onChange={(e) => onChange(e, _id)}
      />
    </FormControl>
  );
};

export default EditSpeciality;
