import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import axios from "axios";
import { endpointMedicines } from "../../utils/endpoints/apiMedicines";

const initialState = [];

export const setMedicines = createAction("SET_MEDICINES");

export const createMedicine = createAsyncThunk(
  "CREATE_MEDICINE",
  async (medicineData) => {
    const response = await axios.post(endpointMedicines.create, medicineData);
    return response.data;
  }
);

export const getAllMedicines = createAsyncThunk("GET_MEDICINES", async () => {
  const response = await axios.get(endpointMedicines.getAll);
  return response.data;
});

export const getMedicineById = createAsyncThunk(
  "GET_MEDICINE",
  async (medicineId) => {
    const response = await axios.get(endpointMedicines.getById(medicineId));
    return response.data;
  }
);

export const updateMedicine = createAsyncThunk(
  "UPDATE_MEDICINE",
  async (data) => {
    const response = await axios.put(endpointMedicines.update, data);
    return response.data;
  }
);

export const deleteMedicine = createAsyncThunk(
  "DELETE_MEDICINE",
  async (medicineId) => {
    const response = await axios.delete(endpointMedicines.delete(medicineId));
    return response.data;
  }
);

const medicineReducer = createReducer(initialState, {
  [getAllMedicines.fulfilled]: (state, action) => action.payload,
  [deleteMedicine.fulfilled]: (state, action) => {
    const { acknowledged, deletedCount } = action.payload;
    if (acknowledged && deletedCount === 1) {
      const filteredMedicines = state.filter(
        (medicine) => medicine._id !== action.meta.arg
      );
      return filteredMedicines;
    } else {
      return state;
    }
  },
  [createMedicine.fulfilled]: (state, action) => {
    state.push(action.payload);
  },
  [setMedicines]: (state, action) => action.payload,
  [getMedicineById.fulfilled]: (state, action) => action.payload,
  [updateMedicine.fulfilled]: (state, action) => {
    const updatedMedicine = action.payload;
    const index = state.findIndex(
      (medicine) => medicine._id === updatedMedicine._id
    );
    if (index !== -1) {
      state[index] = updatedMedicine;
    }
  },
});

export default medicineReducer;
