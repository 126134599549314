import { Route, Routes } from "react-router-dom";
import { Grid, GridItem } from "@chakra-ui/react";
import Sidebar from "../../components/Sidebar";
import { AdminRoutes } from "../AdminRoutes";
import { DoctorRoutes } from "../DoctorRoutes";
import { PatientRoutes } from "../PatientRoutes";
import Navbar from "../../components/Navbar";
import { useMediaQuery } from "@chakra-ui/react";
import CreatePassword from "../../components/user/CreatePassword";
/* import ProfilePhoto from "../../commons/ProfilePhoto"; */

export const AuthenticatedRoutes = ({ user }) => {
  const [isDesktop] = useMediaQuery("(min-width: 800px)");

  return (
    <>
      {user.isActivated === false ? (
        <Routes>
          <Route path="/createPassword" element={<CreatePassword />} />
        </Routes>
      ) : (
        <>
          <Navbar />
          <Grid templateColumns={isDesktop && "repeat(12, 1fr)"}>
            <GridItem colSpan={1}>{isDesktop && <Sidebar />}</GridItem>
            <GridItem h="100%" colSpan={11}>
              {user.role === "admin" && <AdminRoutes />}
              {user.role === "doctor" && <DoctorRoutes doctor={user} />}
              {user.role === "patient" && <PatientRoutes />}
            </GridItem>
          </Grid>
        </>
      )}
    </>
  );
};
