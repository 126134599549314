import { Avatar, List, ListItem } from "@chakra-ui/react";
import avatarReal from "../../assets/logotipos/Isotipo-Color.jpg";
import React from "react";
const UserDetailGeneral = ({ user }) => {
  const fecha = new Date(user.dateOfBirth);
  const dia = fecha.getDate();
  const mes = fecha.getMonth() + 1;
  const anio = fecha.getFullYear();
  return (
    <List spacing={3} key={user._id}>
      <ListItem>
        <Avatar size={"xs"} src={avatarReal} mr={"2"} />
        Identificación: {user.identificacion}
      </ListItem>
      <ListItem>
        <Avatar size={"xs"} src={avatarReal} mr={"2"} />
        Address: {user.address}, {user.state}, {user.city}, {user.country}
      </ListItem>
      <ListItem>
        <Avatar size={"xs"} src={avatarReal} mr={"2"} />
        Email: {user.email}
      </ListItem>
      <ListItem>
        <Avatar size={"xs"} src={avatarReal} mr={"2"} />
        Phone: {user.phone}
      </ListItem>
      <ListItem>
        <Avatar size={"xs"} src={avatarReal} mr={"2"} />
        FN: {dia}/{mes}/{anio}
      </ListItem>
      <ListItem>
        <Avatar size={"xs"} src={avatarReal} mr={"2"} />
        Sexo : {user.gender}
      </ListItem>
      <ListItem>
        <Avatar size={"xs"} src={avatarReal} mr={"2"} />
        Role: {user.role}
      </ListItem>
    </List>
  );
};

export default UserDetailGeneral;
