import React, { useState } from "react";
import DoctorStatistic from "./DoctorStatistic";
import {
  Input,
  Box,
  List,
  ListItem,
  ListIcon,
  Center,
  Flex,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { MdCheckCircle } from "react-icons/md";
import CreateUser from "../user/CreateUser";
import Signature from "../../commons/Signature";
import TableCitas from "./GestCitas/TableCitas";

const HomeDoctor = () => {
  const pacientes = useSelector((state) => state.patients);
  const doctor = useSelector((state) => state.user);
  const citas = useSelector((state) => state.citas);
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
  };

  const filteredData = pacientes.filter((item) =>
    item.identificacion.includes(searchTerm.toLowerCase())
  );

  return (
    <>
      {doctor.firma ? (
        <>
          <Center mb={5}>
            <Box
              display={"-ms-inline-flexbox"}
              mt={"20px"}
              p="2"
              justifyContent={"center"}
            >
              <Flex>
                <Input
                  width="300px"
                  placeholder="Buscar Paciente por identificación"
                  value={searchTerm}
                  onChange={handleSearch}
                  size="sm"
                  bg="white"
                  borderRadius="xl"
                  boxShadow="md"
                />
                <CreateUser />
              </Flex>
              {searchTerm && (
                <>
                  {filteredData.length > 0 && (
                    <List color={"eira.2"} fontWeight={"bold"}>
                      {filteredData.map((item) => (
                        <Link to={`/paciente/${item._id}`} key={item._id}>
                          <ListItem
                            borderRadius={"2xl"}
                            py={1}
                            bg={"white"}
                            my={2}
                            key={item._id}
                            style={{ cursor: "pointer" }}
                          >
                            <ListIcon as={MdCheckCircle} color="green.500" />
                            {item.name}
                          </ListItem>
                        </Link>
                      ))}
                    </List>
                  )}
                </>
              )}
            </Box>
          </Center>
          <Box px={2}>
            <DoctorStatistic />
          </Box>
          <Box bg={"white"} boxShadow="md" borderRadius={"xl"}>
            <TableCitas data={citas} path={"citas"} />
          </Box>
        </>
      ) : (
        <Signature doctor={doctor} />
      )}
    </>
  );
};

export default HomeDoctor;
