import React, { useState } from "react";
import {
  Input,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Center,
  Text,
  Heading,
  useMediaQuery,
  Flex,
  IconButton,
} from "@chakra-ui/react";
import { IoIosAdd } from "react-icons/io";
import { AiFillDelete } from "react-icons/ai";

const FormularioMedicamento = ({
  medicamentos,
  onAddMedicamento,
  onRemoveMedicamento,
}) => {
  const [isDesktop] = useMediaQuery("(min-width: 800px)");
  const [medicamento, setMedicamento] = useState({
    medicamento: "",
    dosis: "",
    frecuencia: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMedicamento((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Verificar si algún campo del medicamento está vacío
    if (
      !medicamento.medicamento ||
      !medicamento.dosis ||
      !medicamento.frecuencia
    ) {
      return; // Evitar agregar medicamento vacío
    }

    onAddMedicamento(medicamento);
    setMedicamento({
      medicamento: "",
      dosis: "",
      frecuencia: "",
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Heading
        size="lg"
        textAlign="center"
        color="eira.2"
        flex="1"
        mb={5}
        mt={{ base: 0, md: 10 }}
      >
        Medicamentos
      </Heading>
      <Center flexDirection={{ base: "column", md: "row" }}>
        <Box mb={{ base: "0.3rem", md: 0 }}>
          <Text textAlign="center" color="eira.2">
            Medicamento
          </Text>
          <Input
            bg="eira.2.1"
            type="text"
            name="medicamento"
            value={medicamento.medicamento}
            onChange={handleChange}
          />
        </Box>
        <Box mx={{ base: 0, md: 5 }} mb={{ base: "0.3rem", md: 0 }}>
          <Text textAlign="center" color="eira.2">
            Dosis
          </Text>
          <Input
            bg="eira.2.1"
            type="text"
            name="dosis"
            value={medicamento.dosis}
            onChange={handleChange}
          />
        </Box>
        <Box>
          <Text textAlign="center" color="eira.2">
            Frecuencia
          </Text>
          <Input
            bg="eira.2.1"
            type="text"
            name="frecuencia"
            value={medicamento.frecuencia}
            onChange={handleChange}
          />
        </Box>
        <Box ml={{ base: 0, md: 5 }} pt={{ base: 5, md: 0 }}>
          <Button
            type="submit"
            size="sm"
            bg="eira.2"
            color="white"
            _hover={{
              bg: "eira.2.5",
            }}
          >
            <IoIosAdd fontSize="20px" />
          </Button>
        </Box>
      </Center>

      {isDesktop ? (
        <Table
          size="sm"
          bg="whiteAlpha.100"
          boxShadow="md"
          borderRadius="xl"
          mt={10}
        >
          <Thead>
            <Tr>
              <Th textAlign="center" color="eira.1">
                Medicamento
              </Th>
              <Th textAlign="center" color="eira.1">
                Dosis
              </Th>
              <Th textAlign="center" color="eira.1">
                Frecuencia
              </Th>
              <Th></Th>
            </Tr>
          </Thead>

          <Tbody>
            {medicamentos.map((med, index) => (
              <Tr key={index}>
                <Td textAlign="center">{med.medicamento}</Td>
                <Td textAlign="center">{med.dosis}</Td>
                <Td textAlign="center">{med.frecuencia}</Td>
                <Td>
                  <Button
                    variant="outline"
                    colorScheme="red"
                    size="xs"
                    onClick={() => onRemoveMedicamento(index)}
                  >
                    <AiFillDelete color="eira.2" />
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <Box mt={5}>
          {medicamentos.map((med, index) => (
            <Flex
              key={index}
              flexDir={"column"}
              border={"1px"}
              borderColor={"eira.2"}
              borderRadius={"xl"}
              mt={2}
              p={2}
            >
              <Text textAlign="center" style={{ wordWrap: "break-word" }}>
                <span style={{ color: "rgba(0, 191, 115)" }}>
                  Medicamento:{" "}
                </span>
                {med.medicamento}
              </Text>

              <Text textAlign="center">
                <span style={{ color: "rgba(0,191,115)" }}>Dosis: </span>{" "}
                {med.dosis}
              </Text>
              <Text textAlign="center">
                <span style={{ color: "rgba(0,191,115)" }}>Frecuencia: </span>{" "}
                {med.frecuencia}
              </Text>
              <Box textAlign={"center"}>
                <IconButton
                  icon={<AiFillDelete fontSize={"20px"} color="red" />}
                  fontSize={"20px"}
                  color="red"
                  onClick={() => onRemoveMedicamento(index)}
                />
              </Box>
            </Flex>
          ))}
        </Box>
      )}
    </form>
  );
};

export default FormularioMedicamento;
