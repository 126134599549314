import React from "react";
import { FiUsers } from "react-icons/fi";
import IconButtonNavbar from "../../commons/IconButtonNavbar";
import { FaStethoscope } from "react-icons/fa";

const SidebarDoctor = ({ path }) => {
  return (
    <>
      <IconButtonNavbar
        text={"Pacientes"}
        icon={<FiUsers fontSize={"25px"} />}
        link={"pacientes"}
        path={path}
      />
      <IconButtonNavbar
        text={"Consultas"}
        icon={<FaStethoscope fontSize={"25px"} />}
        link={"consultas"}
        path={path}
      />
    </>
  );
};

export default SidebarDoctor;
