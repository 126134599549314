import { Avatar, List, ListItem } from "@chakra-ui/react";
import avatarReal from "../../assets/logotipos/Isotipo-Color.jpg";
import React from "react";

const AdminDetail = ({ user }) => {
  return (
    <List spacing={3} key={user._id}>
      <ListItem>
        <Avatar size={"xs"} src={avatarReal} mr={"2"} />
        Position: {user.position}
      </ListItem>
      <ListItem>
        <Avatar size={"xs"} src={avatarReal} mr={"2"} />
        {user.superAdmin ? <>Access: Super Admin</> : <>Access: Admin</>}
      </ListItem>
    </List>
  );
};

export default AdminDetail;
