import React, { useState } from "react";
import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import CreateUser from "../components/user/CreateUser";
import API_BASE_URL from "../utils/apiBaseURL";
import axios from "axios";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { getAllAdmin } from "../store/reducers/admin.reducer";
import TableAdmins from "../components/admin/TableAdmins";
import Pagination from "./Pagination";
import TableSpeciality from "../components/speciality/TableSpeciality";
import TableDoctor from "../components/doctor/TableDoctor";
import TablePatient from "../components/patient/TablePatient";
import TableMedicines from "../components/medicines/TableMedicines";
import { getAllDoctors } from "../store/reducers/doctor.reducer";
import TableAntecedentes from "../components/Servicios/Antecedentes/TableAntecedentes";

const TableView = ({ data, path }) => {
  const ItemsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    setCurrentPage(1);
  };

  const suspendOrActiveUserEndpoint = async (email, endpoint, successTitle) => {
    const resp = await axios.put(`${API_BASE_URL}/api/auth/${endpoint}`, {
      email,
    });
    if (resp) {
      dispatch(getAllAdmin());
      dispatch(getAllDoctors());
      Swal.fire({
        title: successTitle,
        icon: "success",
        timer: 1000,
      });
    }
  };

  const suspendOrActiveUser = async (email, name, state) => {
    const action = state ? "Suspender" : "Activar";
    const confirm = await Swal.fire({
      title: `Deseas ${action} a ${name.split(" ").slice(0, 2).join(" ")}?`,
      confirmButtonText: action,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      allowOutsideClick: false,
      cancelButtonColor: "red",
    });

    if (confirm.isConfirmed) {
      if (state) {
        await suspendOrActiveUserEndpoint(email, "suspendUser", "Suspendido!");
      } else {
        await suspendOrActiveUserEndpoint(email, "activeUser", "Activado!");
      }
    }
  };

  const filteredData = data
    .filter((item) =>
      Object.values(item).some(
        (field) =>
          typeof field === "string" &&
          field.toLowerCase().includes(searchValue.toLowerCase())
      )
    )
    .slice((currentPage - 1) * ItemsPerPage, currentPage * ItemsPerPage);

  return (
    <>
      <Flex>
        <InputGroup maxW="md" boxShadow="md" borderRadius={"xl"}>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            bg="white"
            color="black"
            type="text"
            placeholder="Buscar Usuario"
            _placeholder={{ color: "eira.2.8" }}
            value={searchValue}
            onChange={handleSearchChange}
          />
        </InputGroup>
        <CreateUser path={path} />
      </Flex>
      <Table variant="simple" mt={10} boxShadow="xl" bg={"white"}>
        {path === "admin" && (
          <TableAdmins
            data={filteredData}
            user={user}
            suspendOrActiveUser={suspendOrActiveUser}
          />
        )}
        {path === "especialidades" && (
          <TableSpeciality data={filteredData} user={user} />
        )}
        {path === "medicinas" && (
          <TableMedicines data={filteredData} user={user} />
        )}
        {path === "antecedentesFamiliares" && (
          <TableAntecedentes data={filteredData} user={user} />
        )}
        {path === "antecedentesPersonales" && (
          <TableAntecedentes data={filteredData} user={user} />
        )}
        {path === "medicos" && (
          <TableDoctor
            data={filteredData}
            user={user}
            suspendOrActiveUser={suspendOrActiveUser}
          />
        )}
        {path === "pacientes" && (
          <TablePatient
            data={filteredData}
            user={user}
            suspendOrActiveUser={suspendOrActiveUser}
          />
        )}
      </Table>
      <Pagination
        data={data}
        searchValue={searchValue}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        ItemsPerPage={ItemsPerPage}
      />
    </>
  );
};

export default TableView;
