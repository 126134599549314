import axios from "axios";
import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";

import API_BASE_URL from "../../utils/apiBaseURL";

const initialState = {
  state: "rejected",
};

export const loginRequest = createAsyncThunk("USER_LOGIN", (userData) => {
  return axios
    .post(`${API_BASE_URL}/api/auth/login/`, {
      email: userData.email,
      password: userData.password,
    })
    .then((r) => {
      const { token } = r.data;
      if (r.data.user.isActivated) {
        localStorage.setItem("user", JSON.stringify({ token }));
      }
      return r.data;
    })
    .catch((error) => error.response.data.message);
});

export const logoutRequest = createAsyncThunk("USER_LOGOUT", () => {
  localStorage.clear();
  return { state: "rejected" };
});

export const createPassRequest = createAsyncThunk("CREATE_PASS", (userData) => {
  return axios
    .post(`${API_BASE_URL}/api/auth/newpass`, {
      token: userData.token,
      password: userData.password,
    })
    .then((r) => {
      const { token } = r.data;
      if (r.data.user.isActivated) {
        localStorage.setItem("user", JSON.stringify({ token }));
      }
      return r.data;
    })
    .catch((error) => error.response.data.message);
});

export const forgotPassRequest = createAsyncThunk("FORGOT_PASS", (userData) => {
  return axios
    .put(`${API_BASE_URL}/api/auth/forgot-password`, {
      email: userData,
    })
    .then((r) => r.data);
});

export const resetPassRequest = createAsyncThunk("RESET_PASS", (userData) => {
  const { resetLink, newPassword } = userData;
  return axios
    .put(`${API_BASE_URL}/api/auth/reset-password`, {
      resetLink,
      newPassword,
    })
    .then((r) => r.data);
});

export const setUser = createAction("SET_USER");

export const getUserRequest = createAsyncThunk("GET_USER", (userId) => {
  return axios.get(`/api/users/${userId}`).then((r) => r.data);
});

const userReducer = createReducer(initialState, {
  [loginRequest.fulfilled]: (state, action) => action.payload.user,
  [createPassRequest.fulfilled]: (state, action) => action.payload.user,
  [forgotPassRequest.fullfilled]: (state, action) => action.payload,
  [resetPassRequest.fullfilled]: (state, action) => action.payload,
  [setUser]: (state, action) => action.payload,
  [logoutRequest.fulfilled]: (state, action) => action.payload,
  [getUserRequest.fulfilled]: (state, action) => action.payload,
});

export default userReducer;
