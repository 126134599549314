import React from "react";
import EditUserForm from "../user/EditUserForm";

const EditPatient = ({ register, name, onChange, _id, address, phone }) => {
  return (
    <>
      <EditUserForm
        register={register}
        name={name}
        onChange={onChange}
        _id={_id}
        address={address}
        phone={phone}
      />
    </>
  );
};

export default EditPatient;
