import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addPaciente,
  removePaciente,
} from "../../../store/reducers/patientsByDoctor.reducer";
import TableCitasInfodetail from "./TableCitasInfodetail";
import { Link, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Grid,
  GridItem,
  Heading,
  Stack,
  StackDivider,
  Text,
  Flex,
} from "@chakra-ui/react";
import { useMediaQuery } from "@chakra-ui/react";
import { AiOutlineDelete } from "react-icons/ai";
import { IoIosAdd } from "react-icons/io";
import { FaStethoscope } from "react-icons/fa";
import CreateCitas from "../GestCitas/CreateCitas";
import TableConsultasInfoDetail from "./TableConsultasInfoDetail";

const InfoPatient = () => {
  const [isDesktop] = useMediaQuery("(min-width: 800px)");
  const user = useSelector((state) => state.user);
  const citas = useSelector((state) => state.citas);
  const consultas = useSelector((state) => state.consultas);
  const patientsByDoctor = useSelector((state) => state.patientsByDoctor);
  const patients = useSelector((state) => state.patients);
  const dispatch = useDispatch();
  const { patientId } = useParams();
  const patient = patients.find((patient) => patient._id === patientId);

  const citasDelPaciente = citas.filter(
    (cita) => cita.paciente._id === patientId
  );
  const consultasDelPaciente = consultas.filter(
    (elemento) => elemento.paciente._id === patientId
  );
  const patientOfTheDoctor = patientsByDoctor.find(
    (patient) => patient._id === patientId
  );
  const isPatientRegistred = Boolean(patientOfTheDoctor);

  const addPatient = () => {
    dispatch(addPaciente({ doctorId: user._id, pacienteId: patientId }));
  };

  const removePatient = () => {
    dispatch(removePaciente({ doctorId: user._id, pacienteId: patientId }));
  };

  const fecha = patient?.dateOfBirth ? new Date(patient.dateOfBirth) : null;
  const dia = fecha?.getDate();
  const mes = fecha?.getMonth() + 1;
  const anio = fecha?.getFullYear();

  if (!patient) {
    return <div>Cargando Tabla...</div>;
  }
  return (
    <Center mt={5} pb={20}>
      <Card>
        <CardHeader>
          <Heading size="md" textAlign="center" color="eira.2" flex="1">
            Información General
          </Heading>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Flex ml={3}>
              <CreateCitas paciente={patientId} />

              <Link to={`/consulta/${patientId}`}>
                <Center>
                  <Button
                    color={"white"}
                    bg="eira.1"
                    textAlign={"center"}
                    _hover={{ bg: "eira.1.5" }}
                    size={isDesktop ? "sm" : "xs"}
                    mx={4}
                    mt={2}
                  >
                    <FaStethoscope color="white" fontSize={"20px"} />
                  </Button>
                </Center>
              </Link>

              {!isPatientRegistred ? (
                <Button
                  color={"white"}
                  bg="eira.1"
                  textAlign={"center"}
                  _hover={{ bg: "eira.1.5" }}
                  size={isDesktop ? "sm" : "xs"}
                  mt={2}
                  onClick={addPatient}
                >
                  <IoIosAdd color="white" fontSize={"20px"} />
                </Button>
              ) : (
                <Button
                  color={"white"}
                  bg="red"
                  textAlign={"center"}
                  _hover={{ bg: "eira.1.5" }}
                  size={isDesktop ? "sm" : "xs"}
                  mr={3}
                  mt={2}
                  onClick={removePatient}
                >
                  <AiOutlineDelete color="white" fontSize={"20px"} />
                </Button>
              )}
            </Flex>
          </Box>
        </CardHeader>

        <CardBody>
          <Stack divider={<StackDivider />} spacing="4">
            <Grid
              templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }}
              gap={4}
              alignItems="center"
            >
              <GridItem h="1">
                <Text pt="2" fontSize="md" color={"gray.600"}>
                  <strong style={{ color: "rgba(0,191,115)" }}>Nombre: </strong>
                  {patient.name}
                </Text>
              </GridItem>
              <GridItem h="1">
                <Text pt="2" fontSize="md" color={"gray.600"}>
                  <strong style={{ color: "rgba(0,191,115)" }}>
                    Identificación:{" "}
                  </strong>
                  {patient.identificacion}
                </Text>
              </GridItem>

              <GridItem h="1">
                <Text pt="2" fontSize="md" color={"gray.600"}>
                  <strong style={{ color: "rgba(0,191,115)" }}>
                    Fecha de nacimiento:{" "}
                  </strong>
                  {dia}/{mes}/{anio}
                </Text>
              </GridItem>
              <GridItem h="1">
                <Text pt="2" fontSize="md" color={"gray.600"}>
                  <strong style={{ color: "rgba(0,191,115)" }}>Sexo: </strong>
                  {patient.gender}
                </Text>
              </GridItem>
              <GridItem h="1">
                <Text pt={2} fontSize="md" color={"gray.600"}>
                  <strong style={{ color: "rgba(0,191,115)" }}>Email: </strong>
                  {patient.email}
                </Text>
              </GridItem>
              <GridItem h="1">
                <Text pt="2" fontSize="md" color={"gray.600"}>
                  <strong style={{ color: "rgba(0,191,115)" }}>
                    Teléfono:{" "}
                  </strong>
                  {patient.phone}
                </Text>
              </GridItem>
              <GridItem>
                <Text pt="2" fontSize="md" color={"gray.600"}>
                  <strong style={{ color: "rgba(0,191,115)" }}>
                    Dirección:{" "}
                  </strong>
                  {patient.address}, {patient.state}, {patient.city},{" "}
                  {patient.country}
                </Text>
              </GridItem>
            </Grid>
          </Stack>
        </CardBody>

        <Flex mt={4} flexDir={isDesktop ? "row" : "column"}>
          <Box>
            <CardHeader>
              <Heading size="md" textAlign={"center"} color={"eira.2"}>
                Citas
              </Heading>
            </CardHeader>

            <CardBody>
              <Stack divider={<StackDivider />} spacing="2">
                <TableCitasInfodetail data={citasDelPaciente} />
              </Stack>
            </CardBody>
          </Box>
          <Box>
            <CardHeader>
              <Heading size="md" textAlign={"center"} color={"eira.2"}>
                Consultas
              </Heading>
            </CardHeader>

            <CardBody>
              <Stack divider={<StackDivider />} spacing="4">
                <TableConsultasInfoDetail
                  consultasDelPaciente={consultasDelPaciente}
                />
              </Stack>
            </CardBody>
          </Box>
        </Flex>
      </Card>
    </Center>
  );
};

export default InfoPatient;
