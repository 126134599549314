import React from "react";
import { Badge, Box, Grid, GridItem, Heading, Text } from "@chakra-ui/react";

const InformacionConsulta = ({ paciente }) => {
  const fecha = new Date(paciente.dateOfBirth);
  const dia = fecha.getDate();
  const mes = fecha.getMonth() + 1;
  const anio = fecha.getFullYear();

  let antecedentes = [];
  if (paciente.antecedentes && paciente.antecedentes.length > 0) {
    antecedentes = paciente.antecedentes[0]
      .split(",")
      .filter((antecedente) => antecedente !== "");
  }

  return (
    <Box>
      <Box pb={10}>
        <Heading
          size="lg"
          textAlign="center"
          color="eira.2"
          flex="1"
          mb={10}
          mt={{ base: 0, md: 10 }}
        >
          Información General
        </Heading>
        <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={2}>
          <GridItem>
            <Text color="eira.3">Nombre: {paciente.name}</Text>
          </GridItem>
          <GridItem>
            <Text color="eira.3">
              Identificación: {paciente.identificacion}
            </Text>
          </GridItem>
          <GridItem>
            <Text color="eira.3">
              Fecha de nacimiento: {dia}/{mes}/{anio}
            </Text>
          </GridItem>
          <GridItem>
            <Text color="eira.3">Sexo: {paciente.gender}</Text>
          </GridItem>
          <GridItem>
            <Text color="eira.3">Teléfono: {paciente.phone}</Text>
          </GridItem>
          <GridItem>
            <Text color="eira.3">
              Dirección: {paciente.address}, {paciente.state}, {paciente.city},{" "}
              {paciente.country}
            </Text>
          </GridItem>
        </Grid>
      </Box>
      <Box py={{ base: 2, md: 10 }}>
        {antecedentes.length > 0 && (
          <>
            <Heading size="md" textAlign="center" color="eira.2" mb={3}>
              Antecedentes
            </Heading>
            <Box justifyContent="center" textAlign="center">
              {antecedentes.map((ant, index) => (
                <Badge key={index} bg="eira.1" color="white" mx={2}>
                  {ant}
                </Badge>
              ))}
            </Box>
          </>
        )}
      </Box>

      <Text textAlign="center" color="eira.2" mb={10}>
        {/*         Horario de la cita: {diaCita}/{mesCita}/{anioCita}-{cita.hora}hs */}
      </Text>

      {/*       <Center>
        {cita.estado === "Pendiente" ? (
          <Button
            bg="eira.1"
            color="white"
            _hover={{
              bg: "eira.1",
            }}
            onClick={() => changeStateCita(cita)}
          >
            Confirmar
          </Button>
        ) : (
          <Badge bg="eira.2" color="white">
            Confirmada
          </Badge>
        )}
      </Center> */}
    </Box>
  );
};

export default InformacionConsulta;
