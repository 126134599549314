import { FormControl, Input, Text } from "@chakra-ui/react";
import React from "react";

const EditMedicine = ({
  register,
  onChange,
  _id,
  brandName,
  genericName,
  healtRegister,
  representative,
}) => {
  return (
    <>
      <FormControl isRequired>
        <Text as={"b"} color={"eira.2.7"}>
          Marca
        </Text>
        <Input
          {...register("brandName")}
          value={brandName}
          focusBorderColor="eira.2"
          color={"eira.2"}
          onChange={(e) => onChange(e, _id)}
        />
      </FormControl>
      <FormControl isRequired>
        <Text as={"b"} color={"eira.2.7"}>
          Nombre Genérico
        </Text>
        <Input
          {...register("genericName")}
          value={genericName}
          focusBorderColor="eira.2"
          color={"eira.2"}
          onChange={(e) => onChange(e, _id)}
        />
      </FormControl>
      <FormControl>
        <Text as={"b"} color={"eira.2.7"}>
          Reg Sanitario
        </Text>
        <Input
          {...register("healthRegister")}
          value={healtRegister}
          focusBorderColor="eira.2"
          color={"eira.2"}
          onChange={(e) => onChange(e, _id)}
        />
      </FormControl>
      <FormControl>
        <Text as={"b"} color={"eira.2.7"}>
          Representante
        </Text>
        <Input
          {...register("representative")}
          value={representative}
          focusBorderColor="eira.2"
          color={"eira.2"}
          onChange={(e) => onChange(e, _id)}
        />
      </FormControl>
    </>
  );
};

export default EditMedicine;
