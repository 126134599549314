import { getAllAdmin } from "../store/reducers/admin.reducer";
import { getAllDoctors } from "../store/reducers/doctor.reducer";
import { getAllSpecialities } from "../store/reducers/speciality.reducer";
import { getAllPatients } from "../store/reducers/patient.reducer";
import { getPatientsByDoctor } from "../store/reducers/patientsByDoctor.reducer";
import { getAllMedicines } from "../store/reducers/medicine.reducer";
import { getConsultasByDoctor } from "../store/reducers/consulta.reducer";
import {
  getCitasByDoctor,
  getCitasByPaciente,
} from "../store/reducers/citas.reducer";

export function getStates(user, dispatch) {
  switch (user.role) {
    case "admin":
      dispatch(getAllAdmin());
      dispatch(getAllDoctors());
      dispatch(getAllSpecialities());
      dispatch(getAllPatients());
      dispatch(getAllMedicines());
      break;
    case "doctor":
      dispatch(getAllPatients());
      dispatch(getAllMedicines());
      dispatch(getAllMedicines());
      if (user._id) {
        dispatch(getConsultasByDoctor(user._id));
        dispatch(getPatientsByDoctor(user._id));
        dispatch(getCitasByDoctor(user._id));
      }
      break;
    case "patient":
      if (user._id) {
        dispatch(getCitasByPaciente(user._id));
        dispatch(getAllDoctors());
      }
      break;
    default:
      break;
  }
}
