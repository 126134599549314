import React from "react";
import { Route, Routes } from "react-router-dom";
import HomeAdmin from "../components/admin/HomeAdmin";
import TableUsers from "../components/user/TableUsers";
import ServiceTabs from "../components/Servicios/ServiceTabs";

export const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomeAdmin />} />
      <Route path="/admin" element={<TableUsers />} />
      <Route path="/especialidades" element={<TableUsers />} />
      <Route path="/medicos" element={<TableUsers />} />
      <Route path="/pacientes" element={<TableUsers />} />
      <Route path="/medicinas" element={<TableUsers />} />
      <Route path="/Servicios" element={<ServiceTabs />} />
    </Routes>
  );
};
