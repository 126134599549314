import { FormControl, Input, Text } from "@chakra-ui/react";
import React from "react";
import EditUserForm from "../user/EditUserForm";

const EditDoctor = ({
  register,
  name,
  onChange,
  _id,
  address,
  phone,
  identificacionMedica,
}) => {
  return (
    <>
      <EditUserForm
        register={register}
        name={name}
        onChange={onChange}
        _id={_id}
        address={address}
        phone={phone}
      />

      <FormControl isRequired>
        <Text as={"b"} color={"eira.2.7"}>
          Identificacion Médica
        </Text>
        <Input
          {...register("identificacionMedica")}
          value={identificacionMedica}
          focusBorderColor="eira.2"
          color={"eira.2"}
          onChange={(e) => onChange(e, _id)}
        />
      </FormControl>
    </>
  );
};

export default EditDoctor;
