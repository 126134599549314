import React from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  SimpleGrid,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import API_BASE_URL from "../../../utils/apiBaseURL";
import defaultAvatar from "../../../assets/userO.png";

const FinalizarConsulta = ({ consulta, onSubmit, paciente }) => {
  let profilePhoto = `${API_BASE_URL}/api/utils/uploads/profilePhoto/${paciente.profilePhoto}`;
  const fecha = new Date(paciente.dateOfBirth);
  const anio = fecha.getFullYear();
  const edad = 2023 - anio;

  return (
    <Box boxShadow="md" borderRadius={"xl"}>
      <Box>
        <Center>
          <Avatar size={"xl"} src={profilePhoto || defaultAvatar} />
        </Center>
        <Text mt={2} color={"eira.2"} fontSize={"xl"} textAlign={"center"}>
          {paciente.name}
        </Text>
        <Text color={"eira.2"} fontSize={"xl"} textAlign={"center"}>
          Edad: {edad}
        </Text>
      </Box>

      <Box mt={4} boxShadow={"md"} pb={4} mb={6}>
        <Text color={"eira.2"} fontSize={"xl"} mb={2} textAlign={"center"}>
          Variables
        </Text>
        <Flex flexWrap="wrap" justifyContent="center" textAlign="center">
          <Badge variant="outline" colorScheme="purple" mx={4} color={"eira.1"}>
            Temperatura: {consulta.variables.temperatura}
          </Badge>
          <Badge variant="outline" colorScheme="purple" mx={4} color={"eira.1"}>
            pulso: {consulta.variables.pulso}
          </Badge>
          <Badge variant="outline" colorScheme="purple" mx={4} color={"eira.1"}>
            respiracion: {consulta.variables.respiracion}
          </Badge>
          <Badge variant="outline" colorScheme="purple" mx={4} color={"eira.1"}>
            Tensión Arteriar: {consulta.variables.tensionArterialMN}/
            {consulta.variables.tensionArterialMX}
          </Badge>
        </Flex>
      </Box>
      <Heading textAlign={"center"} color={"eira.2"} size={"lg"}>
        Consulta
      </Heading>
      <Center>
        <Text color={"eira.2"} fontSize={"1xl"}>
          Motivo de consulta:
        </Text>
        {consulta.consulta.motivoConsulta.map((mot, index) => (
          <Badge
            key={index} // Agregar una prop "key" única usando el índice del bucle
            variant="outline"
            colorScheme="purple"
            mx={1}
            color={"eira.1"}
          >
            {mot}
          </Badge>
        ))}
      </Center>
      <SimpleGrid columns={2} spacingX="30px" spacingY="40px" my={10}>
        <Box>
          <Text color={"eira.2"} fontSize={"xl"} mb={2} textAlign={"center"}>
            Enfermedad Actual
          </Text>
          <Text textAlign={"center"}>{consulta.consulta.enfermedadActual}</Text>
        </Box>

        <Box>
          <Text color={"eira.2"} fontSize={"xl"} mb={2} textAlign={"center"}>
            Diagnostico
          </Text>
          <Text textAlign={"center"}>
            {consulta.consulta.diagnosticoConsulta}
          </Text>
        </Box>
        <Box>
          <Text color="eira.2" fontSize="xl" mb={2} textAlign="center">
            Examenes a realizar
          </Text>
          <Flex flexWrap="wrap" justifyContent="center" textAlign="center">
            {consulta.examenes.map((mot, index) => (
              <Badge
                key={index}
                variant="outline"
                colorScheme="purple"
                mx={2}
                my={1}
                color={"eira.1"}
              >
                {mot}
              </Badge>
            ))}
          </Flex>
        </Box>

        <Box>
          <Text color={"eira.2"} fontSize={"xl"} mb={2} textAlign={"center"}>
            Medicamentos
          </Text>
          <Center my={5}>
            <TableContainer width={"400px"}>
              <Table size="sm" variant="simple">
                <Thead>
                  <Tr>
                    <Th color={"eira.1"} textAlign={"center"}>
                      Medicamento
                    </Th>
                    <Th color={"eira.1"} textAlign={"center"}>
                      Dosis
                    </Th>
                    <Th color={"eira.1"} textAlign={"center"}>
                      Frecuencia
                    </Th>
                  </Tr>
                </Thead>
                {consulta.medicamentos ? (
                  <Tbody>
                    {consulta.medicamentos.map((mot, index) => (
                      <Tr key={index}>
                        <>
                          <Td color={"eira.1"} textAlign={"center"}>
                            {mot.medicamento}
                          </Td>
                          <Td color={"eira.1"} textAlign={"center"}>
                            {mot.dosis}
                          </Td>
                          <Td color={"eira.1"} textAlign={"center"}>
                            {mot.frecuencia}
                          </Td>
                        </>
                      </Tr>
                    ))}
                  </Tbody>
                ) : (
                  <Tbody>
                    <Tr>
                      <Td colSpan={3} textAlign={"center"}>
                        No hay medicamentos registrados.
                      </Td>
                    </Tr>
                  </Tbody>
                )}
              </Table>
            </TableContainer>
          </Center>
        </Box>
      </SimpleGrid>
      <Center>
        <Button
          type="submit"
          bg={"eira.1"}
          color={"white"}
          className="animateFloat"
          _hover={{
            bg: "eira.1",
          }}
          onClick={onSubmit}
        >
          Finalizar
        </Button>
      </Center>
    </Box>
  );
};

export default FinalizarConsulta;
