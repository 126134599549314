import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import store from "./store";
import { Global, css } from "@emotion/react";

const customStyles = css`
  body {
    background-color: rgba(237, 241, 251);
  }
`;

const colors = {
  eira: {
    1: "rgba(0,191,115)",
    1.1: "rgba(0,191,115,0.1)",
    1.2: "rgba(0,191,115,0.2)",
    1.3: "rgba(0,191,115,0.3)",
    1.4: "rgba(0,191,115,0.4)",
    1.5: "rgba(0,191,115,0.5)",
    1.6: "rgba(0,191,115,0.6)",
    1.7: "rgba(0,191,115,0.7)",
    1.8: "rgba(0,191,115,0.8)",
    1.9: "rgba(0,191,115,0.9)",
    2: "rgba(0,167,170)",
    2.1: "rgba(0,167,170,0.1)",
    2.2: "rgba(0,167,170,0.2)",
    2.3: "rgba(0,167,170,0.3)",
    2.4: "rgba(0,167,170,0.4)",
    2.5: "rgba(0,167,170,0.5)",
    2.6: "rgba(0,167,170,0.6)",
    2.7: "rgba(0,167,170,0.7)",
    2.8: "rgba(0,167,170,0.8)",
    2.9: "rgba(0,167,170,0.9)",
    3: "rgb(2,91,91)",
    3.1: "rgba(2,91,91,0.1)",
    3.2: "rgba(2,91,91,0.2)",
    3.3: "rgba(2,91,91,0.3)",
    3.4: "rgba(2,91,91,0.4)",
    3.5: "rgba(2,91,91,0.5)",
    3.6: "rgba(2,91,91,0.6)",
    3.7: "rgba(2,91,91,0.7)",
    3.8: "rgba(2,91,91,0.8)",
    3.9: "rgba(2,91,91,0.9)",
  },
};

const theme = extendTheme({ colors });

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <Global styles={customStyles} />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ChakraProvider>
    </Provider>
  </React.StrictMode>
);
