const Swal = require("sweetalert2");

const Toast = Swal.mixin({
  toast: true,
  position: "bottom",
  showConfirmButton: false,
  timer: 2000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
export const handleAddUser = (resp, data, reset, onClose) => {
  if (resp.payload) {
    Toast.fire({
      icon: "success",
      title: `Creado con éxito`,
    });
    reset();
    onClose();
  } else {
    onClose();
    Toast.fire({
      icon: "error",
      title: `Hubo un error al guardar es probable que la data ya exista`,
    });
  }
};
