import {
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react";
import React from "react";

function Variables({ values, onChange, confirmVariables }) {
  return (
    <form>
      <Heading
        size="lg"
        textAlign="center"
        color="eira.2"
        flex="1"
        mb={4}
        mt={{ base: 0, md: 10 }}
      >
        Variables
      </Heading>
      <Flex flexDirection={{ base: "column", md: "row" }} alignItems="center">
        <FormControl flex="1" marginRight="1rem">
          <FormLabel color={"eira.1"}>Temperatura (°C)</FormLabel>
          <Input
            value={values.temperatura}
            onChange={(e) => onChange(e, "temperatura")}
            type="number"
            step="0.1"
            required
          />
        </FormControl>
        <FormControl flex="1" marginRight="1rem" mt={{ base: 5 }}>
          <FormLabel color={"eira.1"}>Pulso (ppm)</FormLabel>
          <Input
            value={values.pulso}
            onChange={(e) => onChange(e, "pulso")}
            type="number"
            required
          />
        </FormControl>
        <FormControl flex="1" marginRight="1rem" mt={{ base: 5 }}>
          <FormLabel color={"eira.1"}>Respiración (rpm)</FormLabel>
          <Input
            value={values.respiracion}
            onChange={(e) => onChange(e, "respiracion")}
            type="number"
            required
          />
        </FormControl>
      </Flex>

      <Flex justifyContent="space-between" marginBottom="1rem">
        <FormControl flex="1" marginRight="1rem" mt={{ base: 5 }}>
          <Center>
            <FormLabel color={"eira.1"} textAlign={"center"}>
              Tensión Arterial
            </FormLabel>
          </Center>
          <Flex
            justifyContent="space-between"
            flexDir={{ base: "column", md: "row" }}
          >
            <Text>min</Text>
            <Input
              placeholder="MIN"
              value={values.tensionArterialMN}
              onChange={(e) => onChange(e, "tensionArterialMN")}
              type="text"
              required
            />
            <Text>max</Text>
            <Input
              placeholder="MAX"
              value={values.tensionArterialMX}
              onChange={(e) => onChange(e, "tensionArterialMX")}
              type="text"
              required
            />
          </Flex>
        </FormControl>

        <FormControl flex="1" marginRight="1rem" mt={{ base: 5 }}>
          <FormLabel color={"eira.1"} textAlign={"center"}>
            Peso (kg)
          </FormLabel>
          <Input
            value={values.peso}
            onChange={(e) => onChange(e, "peso")}
            type="number"
            step="0.1"
            required
          />
        </FormControl>
      </Flex>
    </form>
  );
}

export default Variables;
