import axios from "axios";
import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import apiBaseURL from "../../utils/apiBaseURL";

const initialState = [];

export const createCita = createAsyncThunk("CREATE_CITA", async (citaData) => {
  try {
    const response = await axios.post(
      `${apiBaseURL}/api/citas/create`,
      citaData
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
});

export const getAllCitas = createAsyncThunk("GET_ALL_CITAS", async () => {
  try {
    const response = await axios.get(`${apiBaseURL}/api/citas/all`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
});

export const getCitaById = createAsyncThunk(
  "GET_CITA_BY_ID",
  async (citaId) => {
    try {
      const response = await axios.get(`${apiBaseURL}/api/citas/${citaId}`);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
);

export const updateCita = createAsyncThunk(
  "UPDATE_CITA",
  async ({ citaId, citaData }) => {
    try {
      const response = await axios.put(
        `${apiBaseURL}/api/citas/${citaId}`,
        citaData
      );
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
);

export const changeCitaEstado = createAsyncThunk(
  "CHANGE_CITA_ESTADO",
  async ({ citaId, estado }) => {
    try {
      const response = await axios.post(
        `${apiBaseURL}/api/citas/change-state/${citaId}`,
        { estado: estado }
      );
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
);

export const deleteCita = createAsyncThunk("DELETE_CITA", async (citaId) => {
  try {
    await axios.delete(`${apiBaseURL}/api/citas/${citaId}`);
    return citaId;
  } catch (error) {
    throw error.response.data;
  }
});

export const getCitasByDoctor = createAsyncThunk(
  "GET_CITAS_BY_DOCTOR",
  async (doctorId) => {
    try {
      const response = await axios.get(
        `${apiBaseURL}/api/citas/doctor/${doctorId}`
      );
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
);
export const getCitasByPaciente = createAsyncThunk(
  "GET_CITAS_BY_PACIENTE",
  async (pacienteId) => {
    try {
      const response = await axios.get(
        `${apiBaseURL}/api/citas/paciente/${pacienteId}`
      );
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
);

const citasReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(createCita.fulfilled, (state, action) => {
      state.push(action.payload);
    })
    .addCase(getAllCitas.fulfilled, (state, action) => {
      return action.payload;
    })
    .addCase(getCitaById.fulfilled, (state, action) => {
      const cita = action.payload;
      const index = state.findIndex((c) => c._id === cita._id);
      if (index !== -1) {
        state[index] = cita;
      } else {
        state.push(cita);
      }
    })
    .addCase(updateCita.fulfilled, (state, action) => {
      const updatedCita = action.payload;
      const index = state.findIndex((c) => c._id === updatedCita._id);
      if (index !== -1) {
        state[index] = updatedCita;
      }
    })
    .addCase(changeCitaEstado.fulfilled, (state, action) => {
      const updatedCita = action.payload;
      const index = state.findIndex((c) => c._id === updatedCita._id);
      if (index !== -1) {
        state[index] = updatedCita;
      }
    })
    .addCase(deleteCita.fulfilled, (state, action) => {
      const citaId = action.payload;
      return state.filter((cita) => cita._id !== citaId);
    })
    .addCase(getCitasByDoctor.fulfilled, (state, action) => {
      return action.payload;
    })
    .addCase(getCitasByPaciente.fulfilled, (state, action) => {
      return action.payload;
    });
});

export default citasReducer;
