import React, { useState } from "react";
import { FaTimes } from "react-icons/fa";
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  FormControl,
  Input,
  Text,
} from "@chakra-ui/react";

const AntecedentesPersonalesForm = ({
  data,
  checkedValues,
  setCheckedValues,
}) => {
  const [searchText, setSearchText] = useState("");

  const antecedentesFamiliares = data.filter((ant) => ant.tipo === "familiar");

  const handleCheckboxChange = (value) => {
    if (checkedValues.includes(value)) {
      setCheckedValues((prevCheckedValues) =>
        prevCheckedValues.filter((val) => val !== value)
      );
    } else {
      setCheckedValues((prevCheckedValues) => [...prevCheckedValues, value]);
    }
    setSearchText(""); // Aquí limpias el campo de búsqueda
  };
  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };
  function removeAntecedente(name) {
    const nuevasEspecialidades = checkedValues.filter(
      (especialidad) => especialidad !== name
    );
    setCheckedValues(nuevasEspecialidades);
  }
  const filteredOptions = antecedentesFamiliares.filter((option) =>
    option.name.toLowerCase().includes(searchText.toLowerCase())
  );
  return (
    <FormControl>
      <Text as={"b"} color={"eira.2.7"}>
        Antecedentes Familiares
      </Text>
      <Box>
        <Input
          focusBorderColor="eira.2"
          size={"sm"}
          _placeholder={{ color: "eira.2.4" }}
          color={"eira.2"}
          value={searchText}
          onChange={handleSearchTextChange}
        />
        {searchText && (
          <CheckboxGroup value={checkedValues}>
            {filteredOptions.slice(0, 3).map((option) => (
              <Box borderRadius={"2xl"} py={1} bg={"eira.2.1"} my={1} px={4}>
                <Checkbox
                  colorScheme="green"
                  key={option._id}
                  value={option._id}
                  onChange={() => handleCheckboxChange(option.name)}
                >
                  {option.name}
                </Checkbox>
              </Box>
            ))}
          </CheckboxGroup>
        )}
        {checkedValues.map((option) => (
          <Button
            key={option}
            size={"xs"}
            bg={"eira.2"}
            color={"white"}
            variant="outline"
            textAlign={"center"}
            _hover={{
              bg: "red.500",
              color: "white",
              _before: {
                display: "inline-block",
                marginRight: "5px",
                fontSize: "16px",
                lineHeight: "1",
                textAlign: "center",
                verticalAlign: "middle",
                fontWeight: "bold",
                width: "16px",
                height: "16px",
                background: "white",
                borderRadius: "50%",
                color: "red.500",
                boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.75)",
              },
            }}
            onClick={() => removeAntecedente(option)}
          >
            {option}
            <FaTimes style={{ marginLeft: "5px", display: "inline-block" }} />
          </Button>
        ))}
      </Box>
    </FormControl>
  );
};

export default AntecedentesPersonalesForm;
