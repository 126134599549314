import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import Variables from "./Variables";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import InformacionConsulta from "./InformacionConsulta";
import FormularioConsulta from "./FormularioConsulta";
import Medicamentos from "./Medicamentos";
import Examenes from "./Examenes";
import FinalizarConsulta from "./FinalizarConsulta";
import FinalizarConsultaMobile from "./FinalizarConsultaMobile";
import API_BASE_URL from "../../../utils/apiBaseURL";
import { createConsulta } from "../../../store/reducers/consulta.reducer";
import axios from "axios";
import { useMediaQuery } from "@chakra-ui/react";
import Swal from "sweetalert2";
import { getCitasByDoctor } from "../../../store/reducers/citas.reducer";
function ConsultasTabs() {
  const [isDesktop] = useMediaQuery("(min-width: 800px)");
  const [tabIndex, setTabIndex] = useState(0);
  const citas = useSelector((state) => state.citas);
  const doctor = useSelector((state) => state.user);
  const pacientes = useSelector((state) => state.patients);
  const navigate = useNavigate();
  const { citaId, pacienteId } = useParams();
  const [cita, setCita] = useState(null);
  const [paciente, setPaciente] = useState(null);
  const [variables, setVariables] = useState(null);
  const [examenesForm, setExamenesForm] = useState([]);
  const [medicamentos, setMedicamentos] = useState([]);
  const [consultaForm, setConsultaForm] = useState({
    motivoConsulta: [],
    enfermedadActual: "",
    diagnosticoConsulta: "",
  });

  const dispatch = useDispatch();
  useEffect(() => {
    const cita = citas.find((cita) => cita._id === citaId) || {};
    if (cita.paciente) {
      setPaciente(cita.paciente);
    }
    const paciente =
      pacientes.find((paciente) => paciente._id === pacienteId) || {};
    setPaciente(paciente);
    setCita(cita);

    if (cita && cita.paciente && cita.paciente.variables) {
      setVariables(cita.paciente.variables);
    }
    if (paciente && paciente.variables) {
      setVariables(paciente.variables);
    }
  }, [citas, citaId]);

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };
  const handleFormVariables = (event, fieldName) => {
    setVariables({ ...variables, [fieldName]: event.target.value });
  };
  const handleFormConsulta = (event, fieldName) => {
    const value = event.target ? event.target.value : event; // Extraer el valor del evento
    setConsultaForm({ ...consultaForm, [fieldName]: value });
  };
  const handleAddMedicamento = (medicamento) => {
    setMedicamentos((prevMedicamentos) => [...prevMedicamentos, medicamento]);
  };
  const handleRemoveMedicamento = (index) => {
    setMedicamentos((prevMedicamentos) => {
      const updatedMedicamentos = [...prevMedicamentos];
      updatedMedicamentos.splice(index, 1);
      return updatedMedicamentos;
    });
  };
  const consulta = {
    variables,
    consulta: consultaForm,
    medicamentos,
    examenes: examenesForm,
    cita: cita?._id,
    paciente: paciente?._id || (cita && cita.paciente && cita.paciente._id),
    doctor: doctor?._id,
  };
  const handleConfirmVariables = async () => {
    if (cita && cita.paciente) {
      const idPaciente = cita.paciente._id;
      await axios.put(
        `${API_BASE_URL}/api/doctor/pacientes/${idPaciente}`,
        variables
      );
    }
  };
  const handleSubmit = async () => {
    const resp = await dispatch(createConsulta(consulta));
    if (resp) {
      await Swal.fire({
        title: "Finalizada",
        text: "La consulta ha sido creada correctamente",
        icon: "success",
      });
      dispatch(getCitasByDoctor(doctor._id));
      navigate("/");
    }
  };
  const tabTittle = [
    "Información",
    "Variables",
    "Consulta",
    "Indicaciones",
    "Examenes",
    "Finalizar Consulta",
  ];
  const incrementar = () => {
    setTabIndex((prevCount) => prevCount + 1);
  };
  const disminuir = () => {
    if (tabIndex > 0) {
      setTabIndex((prevCount) => prevCount - 1);
    }
  };
  return (
    <>
      <Box
        bg={"white"}
        boxShadow="md"
        borderRadius={"xl"}
        mt={isDesktop && 5}
        py={isDesktop && 10}
        px={isDesktop && 5}
        mx={isDesktop && 5}
      >
        <Tabs index={tabIndex} onChange={handleTabsChange}>
          {isDesktop ? (
            <TabList>
              <Tab color={"eira.2"}>Información</Tab>
              <Tab color={"eira.2"}>Variables</Tab>
              <Tab color={"eira.2"}>Consulta</Tab>
              <Tab color={"eira.2"}>Indicaciones</Tab>
              <Tab color={"eira.2"}>Examenes</Tab>
              <Tab color={"eira.2"}>Finalizar Consulta</Tab>
            </TabList>
          ) : (
            <Box>
              <Flex justifyContent="space-between" pt={10}>
                <Button
                  variant={"outline"}
                  size={"xs"}
                  color="white"
                  bg={"eira.2"}
                  visibility={tabIndex > 0 ? "visible" : "hidden"}
                  onClick={disminuir}
                >
                  {tabTittle[tabIndex - 1]}
                </Button>

                <Button
                  variant={"outline"}
                  size={"xs"}
                  color="white"
                  bg={"eira.2"}
                  visibility={!(tabIndex === 5) ? "visible" : "hidden"}
                  onClick={incrementar}
                >
                  {tabTittle[tabIndex + 1]}
                </Button>
              </Flex>
            </Box>
          )}

          {!cita ? (
            <h1>Cargando</h1>
          ) : (
            <TabPanels>
              <TabPanel>
                <InformacionConsulta
                  paciente={cita.paciente ? cita.paciente : paciente}
                />
              </TabPanel>
              <TabPanel>
                <Variables
                  values={variables}
                  onChange={handleFormVariables}
                  confirmVariables={handleConfirmVariables}
                />
              </TabPanel>
              <TabPanel>
                <FormularioConsulta
                  values={consultaForm}
                  onChange={handleFormConsulta}
                />
              </TabPanel>
              <TabPanel>
                <Medicamentos
                  medicamentos={medicamentos}
                  onAddMedicamento={handleAddMedicamento}
                  onRemoveMedicamento={handleRemoveMedicamento}
                />
              </TabPanel>
              <TabPanel>
                <Examenes
                  checkedValues={examenesForm}
                  setCheckedValues={setExamenesForm}
                />
              </TabPanel>
              <TabPanel>
                {isDesktop ? (
                  <FinalizarConsulta
                    consulta={consulta}
                    onSubmit={handleSubmit}
                    paciente={cita.paciente ? cita.paciente : paciente}
                  />
                ) : (
                  <FinalizarConsultaMobile
                    consulta={consulta}
                    onSubmit={handleSubmit}
                    paciente={cita.paciente ? cita.paciente : paciente}
                  />
                )}
              </TabPanel>
            </TabPanels>
          )}
        </Tabs>
      </Box>
    </>
  );
}

export default ConsultasTabs;
