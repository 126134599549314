import React, { useState } from "react";
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Table,
  Th,
  Thead,
  Tr,
  useMediaQuery,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
import CreateUser from "../../../components/user/CreateUser";
import { useSelector } from "react-redux";
import Pagination from "../../../commons/Pagination";
import { CgDetailsMore } from "react-icons/cg";
import { Link } from "react-router-dom";

const TablePatientsByDoctor = () => {
  const [isDesktop] = useMediaQuery("(min-width: 800px)");
  const patients = useSelector((state) => state.patientsByDoctor);
  const ItemsPerPage = 6;
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    setCurrentPage(1);
  };

  const filteredPatients = patients
    .filter((item) =>
      Object.values(item).some(
        (field) =>
          typeof field === "string" &&
          field.toLowerCase().includes(searchValue.toLowerCase())
      )
    )
    .slice((currentPage - 1) * ItemsPerPage, currentPage * ItemsPerPage);

  return (
    <Box mt={10} px={isDesktop && 10} overflowX="auto">
      <Flex mx={4}>
        <InputGroup boxShadow="md" borderRadius={"xl"}>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement>
          <Input
            bg="white"
            color="black"
            type="text"
            placeholder="Buscar en tus pacientes"
            _placeholder={{ color: "eira.2.8" }}
            value={searchValue}
            onChange={handleSearchChange}
          />
        </InputGroup>
        <CreateUser />
      </Flex>

      <Table
        variant="simple"
        mt={10}
        bg="white"
        borderRadius="xl"
        boxShadow="md"
        minWidth="100%"
      >
        <Thead>
          <Tr>
            <Th color={"eira.2"}>Nombre</Th>
            <Th color={"eira.2"}>Identificación</Th>
            {isDesktop && (
              <>
                <Th color={"eira.2"}>Email</Th>
                <Th color={"eira.2"}>Telefono</Th>
                <Th color={"eira.2"}>Ir a Detalles</Th>
              </>
            )}
          </Tr>
        </Thead>
        <tbody>
          {filteredPatients.map((item, index) => (
            <Tr key={index}>
              <Th>{item.name.split(" ").slice(0, 1).join(" ")}</Th>
              <Th>{item.identificacion}</Th>
              {isDesktop && (
                <>
                  <Th>{item.email}</Th>
                  <Th>{item.phone}</Th>
                </>
              )}

              <Th>
                <Box textAlign={"center"}>
                  <Link to={`/paciente/${item._id}`}>
                    <Button
                      bg={"eira.2"}
                      color={"white"}
                      size={"xs"}
                      _hover={{ bg: "eira.2.5" }}
                    >
                      <CgDetailsMore fontSize="20px" color="white" />
                    </Button>
                  </Link>
                </Box>
              </Th>
            </Tr>
          ))}
        </tbody>
      </Table>
      <Pagination
        data={patients}
        searchValue={searchValue}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        ItemsPerPage={ItemsPerPage}
      />
    </Box>
  );
};

export default TablePatientsByDoctor;
