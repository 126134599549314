import axios from "axios";
import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import apiBaseURL from "../../utils/apiBaseURL";

const initialState = [];

export const getPatientsByDoctor = createAsyncThunk(
  "GET_PATIENTS_BY_DOCTOR",
  (doctorId) => {
    return axios
      .get(`${apiBaseURL}/api/doctor/allPatients/${doctorId}`)
      .then((r) => r.data);
  }
);

export const addPaciente = createAsyncThunk(
  "ADD_PACIENTE",
  ({ doctorId, pacienteId }) => {
    return axios
      .post(`${apiBaseURL}/api/doctor/${doctorId}/pacientes/${pacienteId}`)
      .then((r) => r.data);
  }
);

export const removePaciente = createAsyncThunk(
  "REMOVE_PACIENTE",
  ({ doctorId, pacienteId }) => {
    return axios
      .delete(`${apiBaseURL}/api/doctor/${doctorId}/pacientes/${pacienteId}`)
      .then((r) => r.data);
  }
);

const patientsByDoctor = createReducer(initialState, (builder) => {
  builder
    .addCase(getPatientsByDoctor.fulfilled, (state, action) => {
      return action.payload;
    })
    .addCase(addPaciente.fulfilled, (state, action) => {
      // Actualizar el estado después de agregar un paciente
      state.push(action.payload);
    })
    .addCase(removePaciente.fulfilled, (state, action) => {
      // Actualizar el estado después de eliminar un paciente
      const pacienteId = action.meta.arg.pacienteId;
      return state.filter((paciente) => paciente._id !== pacienteId);
    });
});

export default patientsByDoctor;
