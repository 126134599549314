import React, { useState } from "react";
import {
  Badge,
  Box,
  Button,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import PaginationCitas from "../../commons/PaginationCitas";
import CreateCitas from "./CreateCita.paciente";
import {
  changeCitaEstado,
  deleteCita,
} from "../../store/reducers/citas.reducer";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

const TableCitasByDoctor = () => {
  const data = useSelector((state) => state.citas);
  const dispatch = useDispatch();
  const ItemsPerPage = 3;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().slice(-2);
    return `${day}/${month}/${year}`;
  };

  const filteredData = data.slice(
    (currentPage - 1) * ItemsPerPage,
    currentPage * ItemsPerPage
  );

  const deleteUserCita = (item) => {
    dispatch(deleteCita(item._id));
  };
  const confirmDelete = async (item) => {
    const confirm = await Swal.fire({
      title: `Deseas eliminar la cita?`,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      allowOutsideClick: false,
      cancelButtonColor: "red",
    });

    if (confirm.isConfirmed) {
      deleteUserCita(item);
    }
  };
  const changeStateCita = (item) => {
    dispatch(changeCitaEstado({ citaId: item._id, estado: "Confirmada" }));
  };

  return (
    <Box>
      <Table variant="simple" bg="white" borderRadius="xl" boxShadow="md">
        <Thead>
          <Tr textAlign="center">
            <Th color="eira.2" textAlign="center">
              Doctor
            </Th>
            <Th color="eira.2" textAlign="center">
              Especialidad
            </Th>
            <Th color="eira.2" textAlign="center">
              Cita
            </Th>
            <Th color="eira.2" textAlign="center">
              Estado
            </Th>
            <Th color="eira.2" textAlign="center">
              Acción
            </Th>
            <Th color="eira.2" textAlign="center">
              <CreateCitas />
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredData &&
            filteredData.map((item, index) => {
              if (item.estado === "Pendiente" || item.estado === "Confirmada") {
                return (
                  <Tr key={index}>
                    <Th textAlign="center">{item.doctor.name}</Th>
                    {item.doctor.especialidades.map((esp) => (
                      <Th textAlign="center" key={esp}>
                        {esp}
                      </Th>
                    ))}
                    <Th textAlign="center">
                      {formatDateTime(item.fecha) + " " + item.hora}
                    </Th>
                    <Th textAlign="center">
                      {item.estado === "Pendiente" ? (
                        <Badge
                          size="xs"
                          bg="eira.1.4"
                          color="blackAlpha.900"
                          onClick={() => {
                            changeStateCita(item);
                          }}
                          _hover={{
                            cursor: "pointer",
                            transform: "scale(1.1)",
                            transition: "transform 0.3s ease-in-out",
                            bg: "eira.2.8",
                            color: "white",
                          }}
                        >
                          Confirmar?
                        </Badge>
                      ) : (
                        <Badge bg="eira.2" color="white">
                          Confirmada
                        </Badge>
                      )}
                    </Th>
                    <Th textAlign="center">
                      <Box>
                        <Button
                          size="xs"
                          bg="eira.1"
                          mx={1}
                          _hover={{ bg: "eira.1.5" }}
                          onClick={() => confirmDelete(item)}
                          color="white"
                        >
                          Eliminar
                        </Button>
                      </Box>
                    </Th>
                  </Tr>
                );
              } else {
                return null;
              }
            })}
        </Tbody>
      </Table>
      <PaginationCitas
        data={data}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        ItemsPerPage={ItemsPerPage}
      />
    </Box>
  );
};

export default TableCitasByDoctor;
