import React from "react";
import TableView from "../../commons/TableView";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Box } from "@chakra-ui/react";

const TableUsers = () => {
  const path = useLocation().pathname.slice(1);
  const admins = useSelector((state) => state.admins);
  const specialities = useSelector((state) => state.specialities);
  const doctors = useSelector((state) => state.doctors);
  const patients = useSelector((state) => state.patients);
  const medicines = useSelector((state) => state.medicines);

  const data = {
    admin: admins,
    especialidades: specialities,
    medicos: doctors,
    pacientes: patients,
    medicinas: medicines,
  };

  return (
    <Box mt={20} px={20}>
      <TableView data={data[path]} path={path} />
    </Box>
  );
};

export default TableUsers;
