import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";
import axios from "axios";
import { endpointSpeciality } from "../../utils/endpoints/apiSpeciality";

const initialState = [];

export const setSpecialities = createAction("SET_SPECIALITIES");

export const createSpeciality = createAsyncThunk(
  "CREATE_SPECIALITY",
  async (data) => {
    const response = await axios.post(endpointSpeciality.create, data);
    return response.data;
  }
);

export const getAllSpecialities = createAsyncThunk(
  "GET_SPECIALITIES",
  async () => {
    const response = await axios.get(endpointSpeciality.getAll);
    return response.data;
  }
);

export const getSpecialityById = createAsyncThunk(
  "GET_SPECIALITY",
  async (specialityId) => {
    const response = await axios.get(endpointSpeciality.getById(specialityId));
    return response.data;
  }
);

export const updateSpeciality = createAsyncThunk(
  "UPDATE_SPECIALITY",
  async (data) => {
    const response = await axios.put(endpointSpeciality.update, data);
    return response.data;
  }
);

export const deleteSpeciality = createAsyncThunk(
  "DELETE_SPECIALITY",
  async (specialityId) => {
    const response = await axios.delete(
      endpointSpeciality.delete(specialityId)
    );
    return response.data;
  }
);

const specialityReducer = createReducer(initialState, {
  [getAllSpecialities.fulfilled]: (state, action) => action.payload,
  [deleteSpeciality.fulfilled]: (state, action) => {
    const { acknowledged, deletedCount } = action.payload;
    if (acknowledged && deletedCount === 1) {
      const filteredSpecialities = state.filter(
        (speciality) => speciality._id !== action.meta.arg
      );
      return filteredSpecialities;
    } else {
      return state;
    }
  },
  [createSpeciality.fulfilled]: (state, action) => {
    state.push(action.payload);
  },
  [setSpecialities]: (state, action) => action.payload,
  [getSpecialityById.fulfilled]: (state, action) => action.payload,
  [updateSpeciality.fulfilled]: (state, action) => {
    const updatedSpeciality = action.payload;
    const index = state.findIndex(
      (speciality) => speciality._id === updatedSpeciality._id
    );
    if (index !== -1) {
      state[index] = updatedSpeciality;
    }
  },
});

export default specialityReducer;
