import React from "react";
import { FaStethoscope } from "react-icons/fa";
import IconButtonNavbar from "../../commons/IconButtonNavbar";

const SidebarPatient = ({ path }) => {
  return (
    <>
      <IconButtonNavbar
        text={"Consultas"}
        icon={<FaStethoscope fontSize={"25px"} />}
        link={"consultas"}
        path={path}
      />
    </>
  );
};

export default SidebarPatient;
