import React from "react";
import { Th, Thead, Tr } from "@chakra-ui/react";
import DeleteUser from "../../../commons/DeleteUser";
import EditUser from "../../../commons/EditUser";

const TableAntecedentes = ({ data, user }) => {
  return (
    <>
      <Thead>
        <Tr>
          <Th color={"eira.2"} textAlign={"center"}>
            Nombre
          </Th>
          <Th color={"eira.2"}>Acción</Th>
        </Tr>
      </Thead>
      <tbody>
        {data.map((item, index) => (
          <Tr key={index}>
            <Th textAlign={"center"}>{item.name}</Th>
            <Th textAlign={"center"}>
              <div style={{ display: "flex" }}>
                {user.superAdmin && (
                  <>
                    <DeleteUser user={item} typeOfItem="antecedentes" />
                    <EditUser user={item} />
                  </>
                )}
              </div>
            </Th>
          </Tr>
        ))}
      </tbody>
    </>
  );
};

export default TableAntecedentes;
