import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  List,
  Avatar,
  Center,
  Divider,
} from "@chakra-ui/react";
import { IoIosEye } from "react-icons/io";
import { useLocation } from "react-router-dom";
import defaultAvatar from "../assets/userO.png";
import AdminDetail from "../components/admin/AdminDetail";
import DoctorDetail from "../components/doctor/DoctorDetail";
import PatientDetail from "../components/patient/PatientDetail";
import UserDetailGeneral from "../components/user/UserDetailGeneral";
import API_BASE_URL from "../utils/apiBaseURL";

const UserDetail = ({ user }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const path = useLocation().pathname.slice(1);

  let profilePhoto = `${API_BASE_URL}/api/utils/uploads/profilePhoto/${user.profilePhoto}`;

  return (
    <>
      <Button
        onClick={onOpen}
        size="sm"
        ml={"2"}
        bg={"eira.2"}
        _hover={{
          bg: "eira.2.5",
        }}
      >
        <IoIosEye fontSize={"20"} color={"#edf2f7"} />
      </Button>
      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color={"eira.2"}>
            <Center>
              <Avatar mb={2} size="xl" src={profilePhoto || defaultAvatar} />
            </Center>
            <Center>{user.name}</Center>
            <Divider mt={5} />
          </ModalHeader>

          <ModalCloseButton />
          <ModalBody pb={6}>
            <List spacing={3}>
              <UserDetailGeneral user={user} />
              {path === "admin" && <AdminDetail user={user} />}
              {path === "medicos" && <DoctorDetail user={user} />}
              {path === "pacientes" && <PatientDetail user={user} />}
            </List>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} variant={"outline"} colorScheme={"blue"}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default UserDetail;
